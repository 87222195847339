import React from 'react';
import PropTypes from 'prop-types';
import { TextWrap, EmbeddedTextWrap, NewLineWrap, ViewWrap } from '../WorksheetWrappers';

import { splitText } from '../LanguageAccessability';
import { questionPropType } from '../../pages/CreateWorksheet/CreateWorksheetConstants';

// Create Document Component
function Glossary({ question, config, isPdf }) {


    return (
        <>
        <TextWrap
                key="gloss"
                isPdf={isPdf}
                styleProps={config}
                styleNames={['h2']}
            >
                Glossary
        </TextWrap>

        <ViewWrap
            isPdf={isPdf}
            styleName="glossaryItems" styleProps={config}>

            {question.glossary_items.map((line, lineIndex) => (
            <div key={`glossaryItem-${lineIndex + 1}}`}>
            <EmbeddedTextWrap
                isPdf={isPdf}
                elementKey={`question-text-${lineIndex + 1}`}
                styleProps={config}
                styleName=''
                key={`glossaryItem-${lineIndex + 1}}`}
            >
                {splitText(line).map((part, index) => (

                    <TextWrap
                        isPdf={isPdf}
                        styleProps={config}
                        styleNames={['questionText', 'inlineText', part.script]}
                        key={`textSegment-${index + 1}`}
                        elementKey={`textSegment-${index + 1}`}
                    >
                        {part.text}
                    </TextWrap>
                ))}

            </EmbeddedTextWrap>
            <NewLineWrap key={`newline-${lineIndex + 1}}`}/>
            </div>
        ))}
        </ViewWrap></>
    );
}

Glossary.propTypes = {
    question: questionPropType,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
    })
}

export default Glossary;

