import React, { useEffect, useState } from 'react';
import "./Banner.css";

function Banner () {
    const [offsetY, setOffsetY] = useState(0);

    useEffect(() => {
        const scrollContainer = document.querySelector('.base-content-container');
        
            const handleScroll = () => {
              setOffsetY(scrollContainer.scrollTop - 175);
            };
      
            scrollContainer.addEventListener('scroll', handleScroll);
      
            return () => {
              scrollContainer.removeEventListener('scroll', handleScroll);
            };
        }, []);
  
    // Adjust parallaxSpeed to control the movement speed
    const parallaxSpeed = 0.1;
    return (
        <div className="banner" style={{ backgroundPositionY: `${offsetY * parallaxSpeed}px` }}/>
    )
}

export default Banner;