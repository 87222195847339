import React, { useEffect } from "react";
import PropTypes from "prop-types";

import "./LoadingBar.css";
import loadingAnimation from "../../assets/shared/loadingAnimation.gif";


function LoadingBar({ duration }) {
  useEffect(() => {
      setTimeout(() => {
          document.documentElement.scrollTop = 0; // For most browsers
          document.body.scrollTop = 0; // For Safari
      }, 0);
  }, []);

  return (
    <div className="loadingBar-container">
        <div className="loadingBar-progress-bar">
            <div 
                className="loadingBar-progress"
                style={{animation: `fill ${duration}s infinite`}}/>
        </div>
        { duration > 10 && <img className="loadingBar-gif" src={loadingAnimation} alt="Loading..."/>}
    </div>
  );
}

LoadingBar.propTypes = {
    duration: PropTypes.string,
}

export default LoadingBar;