import React from "react";
import "./UnderConstruction.css";
import Navbar from "../../components/NavBar/NavBar";
import UnderConstructionImg from '../../assets/UnderConstruction/underConstructionImg.png'


function UnderConstruction() {
	return (
		<div className="base-container">
			<Navbar/>
			<div className="base-content-container">
				<div className="underConstruction-container">
                    <div className="underConstruction-content">
                    <img className="underConstruction-img" src={UnderConstructionImg} alt="Under Construction"/>
                    <h1 className="underConstruction-text">This page is under construction. Come back soon!</h1>
                    </div>
				</div>
			</div>
		</div>
	);
}

export default UnderConstruction;
