import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function OAuthCallback () {
    const location = useLocation();

    useEffect(() => {
        // Function to get query parameters from the URL
        const getQueryParam = (name) => {
            const urlParams = new URLSearchParams(location.search);
            return urlParams.get(name);
        };

        const oauthCode = getQueryParam('code');
        const error = getQueryParam('error');

        if (oauthCode) {
            // Send the OAuth code back to the parent window
            window.opener.postMessage({
                type: 'oauth-code',
                code: oauthCode
            }, window.location.origin);
            window.close(); // Close the popup window
        } else if (error) {
            // Send the error back to the parent window
            window.opener.postMessage({
                type: 'oauth-error',
                error,
            }, window.location.origin);
            window.close(); // Close the popup window
        } else {
            // Handle unexpected cases where neither code nor error is present
            window.opener.postMessage({
                type: 'oauth-error',
                error: 'OAuth code not found and no error provided'
            }, window.location.origin);
            window.close(); // Close the popup window
        }
    }, [location]);

    return (
        <div>
            <p>Handling OAuth callback...</p>
        </div>
    );
};

export default OAuthCallback;
