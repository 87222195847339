import React, { useState } from 'react';
import PropTypes from 'prop-types';

import "../DifferentiationGroups.css";
import { diffGroupsPropType, 
            fontSizes as fontSizesOptions, 
            lineSpacing as lineSpacingOptions 
        } from '../../../CreateWorksheetConstants';

import SliderSelector from '../SharedComponents/SliderSelector';

function ConfigureIEP ({ index, diffGroups, setDiffGroups }) {
    const [expanded, setExpanded] = useState(false);
    const [toggledItems, setToggledItems] = useState({
        fontSize: false,
        lineSpacing: false,
    });

    const isAllItemsInactive = () => 
        !toggledItems.fontSize && !toggledItems.lineSpacing;
    

    const handleToggleFontSize = () => {
        // was active, so need to reset to default
        if (toggledItems.fontSize) {
            console.log("Font reset to default");
        
            const newDiffGroups = [...diffGroups];
            const targetGroup = newDiffGroups[index]

            if (targetGroup === undefined) {
                console.log("Error: cannot find target group");
                return;
            }
            
            targetGroup.accommodations.IEP.accommodations.font_size = 12;

            if (isAllItemsInactive) targetGroup.accommodations.IEP.active = false;

            setDiffGroups(newDiffGroups);
        }

        setToggledItems((prevState) => ({
            ...prevState, // Copy the existing state
            fontSize: !prevState.fontSize, // Toggle the fontSize value
        }));

    };

    const handleToggleLineSpacing = () => {
        // was active, so need to reset to default
        if (toggledItems.lineSpacing) {
            console.log("Line Spacing reset to default");
        
            const newDiffGroups = [...diffGroups];
            const targetGroup = newDiffGroups[index]

            if (targetGroup === undefined) {
                console.log("Error: cannot find target group");
                return;
            }
            
            targetGroup.accommodations.IEP.accommodations.line_spacing = 1.5;

            if (isAllItemsInactive) targetGroup.accommodations.IEP.active = false;
            setDiffGroups(newDiffGroups);
        }

        setToggledItems((prevState) => ({
            ...prevState, // Copy the existing state
            lineSpacing: !prevState.lineSpacing, // Toggle the fontSize value
        }));

    };

    const handleToggleIEP = (expand) => {
        setExpanded(expand);

        if (expand === false) {
            if (toggledItems.fontSize){
                handleToggleFontSize();

            }
            if (toggledItems.lineSpacing) {
                handleToggleLineSpacing();

            }
        }
        

        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index]

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }
        
        targetGroup.accommodations.IEP.active = expand;
        setDiffGroups(newDiffGroups);
    }

    const handleFontSizeChange = (option) => {
        console.log("Font size changed to:", option.title, option.descriptor);
        

        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index]

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }
        // console.log(targetGroup);
        
        targetGroup.accommodations.IEP.accommodations.font_size = parseInt(option.descriptor, 10);
        targetGroup.accommodations.IEP.active = true;
		setDiffGroups(newDiffGroups);
    }

    const handleLineSpacingChange = (option) => {
        console.log("Line spacing changed to:", option.title, option.descriptor);
        

        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index]

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }
        // console.log(targetGroup);
        
        targetGroup.accommodations.IEP.accommodations.line_spacing = parseFloat(option.descriptor, 10);
        targetGroup.accommodations.IEP.active = true;
		setDiffGroups(newDiffGroups);
    }

    return (
        <div>
        <div className="differentiationGroups-accommodations-option">
            <input 
                className="differentiationGroups-accommodations-option-checkbox"
                type="checkbox" 
                id="accommodations-IEP" 
                name="IEP" 
                onClick={() => handleToggleIEP(!expanded)}/>
            <label 
                className="differentiationGroups-accommodations-option-label"
                htmlFor="accommodations-IEP"> 
                Individualized Education Program (IEP) Support
            </label><br/>
        </div>
        { expanded && 
            <div
                style={{display: "flex", flexDirection: "column", gap: "5px", marginLeft: "15px", marginTop: "8px"}}
            >
                <div>
                    <input 
                        className="differentiationGroups-accommodations-option-checkbox"
                        type="checkbox" 
                        id="accommodations-IEP-textSpacing" 
                        name="textSpacing" 
                        onClick={handleToggleFontSize}
                    />
                    <label 
                        className="differentiationGroups-accommodations-option-label"
                        htmlFor="accommodations-IEP-textSpacing"
                        style={{paddingLeft: "2px"}}
                    >
                        Font Size
                    </label><br/>
                </div>
                { toggledItems.fontSize && 
                    <div className="differentiationGroups-group-section">
                        <SliderSelector
                            options={fontSizesOptions}
                            hasDescriptor
                            startingIndex={1}
                            handleChange={(option) => handleFontSizeChange(option)}    
                        />
                    </div>
                }
                <div>
                    <input 
                        className="differentiationGroups-accommodations-option-checkbox"
                        type="checkbox" 
                        id="accommodations-IEP-textSpacing" 
                        name="textSpacing" 
                        onClick={handleToggleLineSpacing}
                    />
                    <label 
                        className="differentiationGroups-accommodations-option-label"
                        htmlFor="accommodations-IEP-textSpacing"
                        style={{paddingLeft: "2px"}}
                    >
                        Line Spacing
                    </label><br/>
                </div>
                { toggledItems.lineSpacing && 
                    <div className="differentiationGroups-group-section">
                        <SliderSelector
                            options={lineSpacingOptions}
                            hasDescriptor
                            startingIndex={1}
                            handleChange={(option) => handleLineSpacingChange(option)}    
                        />
                    </div>
                }
            </div>
        }
        </div>

    );
}

ConfigureIEP.propTypes = {
    index: PropTypes.number,
    diffGroups: diffGroupsPropType,
    setDiffGroups: PropTypes.func,
};

export default ConfigureIEP;