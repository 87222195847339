

async function imageToBase64ImagesFunc(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            try {
                resolve(reader.result);
            } catch (error) {
                console.error('Error in reader onloadend:', error);
                reject(error);
            }
        };
        reader.onerror = (error) => {
            console.error('Error in reader:', error);
            reject(error);
        };
        reader.readAsDataURL(file);
    });
}

export default imageToBase64ImagesFunc;