import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useUser, useFirestore } from 'reactfire';
import { collection, getDocs } from 'firebase/firestore';

import standardsIcon from '../../../../assets/CreateWorksheet/standardsIcon.svg';
import './AlignToStandards.css';
import deleteIcon from '../../../../assets/CreateWorksheet/deleteIcon.svg';
import searchIcon from '../../../../assets/CreateWorksheet/searchIcon.svg';
import expandIcon from '../../../../assets/CreateWorksheet/expandIcon.svg';

import { generalInfoPropType, gradeLevels } from "../../CreateWorksheetConstants";


function AlignToStandards({ generalInfo, setGeneralInfo}) {
    const [expanded, setExpanded] = useState(false);

    const [allStandards, setAllStandards] = useState([]);

    const searchInputRef = useRef();

    const [selectedGrade, setSelectedGrade] = useState('');

    const firestore = useFirestore();
    const { data: user } = useUser();

    useEffect(() => {
        const fetchStandards = async () => {
          try {
                if (!user) {
                    console.error("User is not authenticated");
                    return;
                }
        
                const subcollections = [
                    'common_core_language',
                    'common_core_history_social_studies_6-12',
                    'common_core_reading_literature',
                    'common_core_science_6-12',
                    'common_core_writing',
                    'common_core_writing_6-12',
                    'common_core_reading_informational_text',
                ];
        
                // Fetch all standards concurrently
                const promises = subcollections.map(async (subcollection) => {
                    const standardsRef = collection(firestore, 'standards', 'ela_standards', subcollection);
                    const querySnapshot = await getDocs(standardsRef);
                    return querySnapshot.docs.map(doc => doc.data());
                });
        
                // Await all promises and flatten results
                const standardsList = (await Promise.all(promises)).flat();
                setAllStandards(standardsList);
            } catch (error) {
                console.error("Error fetching standards:", error);
            }
        };
      
        fetchStandards();
    }, [firestore, user]);
      


    const handleRemoveStandard = (indexToRemove) => {
        setGeneralInfo((prevGenInfo) => ({
            ...prevGenInfo,
            standards: prevGenInfo.standards.filter((_, index) => index !== indexToRemove)
        }));
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            const standardToAdd = allStandards.find((standard) => standard.standard_code === value);
            setGeneralInfo((prevGenInfo) => ({
                ...prevGenInfo,
                standards: [...prevGenInfo.standards, standardToAdd]
            }));
        } else {
            setGeneralInfo((prevGenInfo) => ({
                ...prevGenInfo,
                standards: prevGenInfo.standards.filter((standard) => standard.standard_code !== value)
            }));
        }
    };

    const handleSearchChange = () => {
        setGeneralInfo((prevGenInfo) => ({
            ...prevGenInfo,
            standards: [...prevGenInfo.standards],
        }));
    };
    
    const handleGradeChange = (event) => {
        setSelectedGrade(event.target.value);
    };

    const getFilteredStandards = () => {
        const query = searchInputRef.current ? searchInputRef.current.value.toLowerCase() : "";
        
        return allStandards.filter((standard) => {
            const codeParts = standard.standard_code.split('.');
            const gradePart = codeParts[3];
            
            const gradeMatches = selectedGrade === '' || gradePart.split('-').some((part) => {
                const [start, end] = part.split('-').map(Number);
                if (end) {
                // It's a range
                const selectedGradeNum = Number(selectedGrade);
                return selectedGradeNum >= start && selectedGradeNum <= end;
                }
                return part === selectedGrade;
            });
        
            return (
                (standard.standard_code.toLowerCase().includes(query) ||
                standard.description.toLowerCase().includes(query)) &&
                gradeMatches
            );
        });
    };

    const filteredStandards = allStandards ? getFilteredStandards() : [];
    
    return (
        <div className="createWorksheet-configure-info-section"
            style={{
                flex: expanded ? "1 1 100%" : "1 0 0", // Full row when expanded
                width: expanded ? "100%" : "auto", // Span the row if expanded
            }}
        >
            <div
                className="createWorksheet-configure-info-header"
                >
                <div 
                    style={{display: "flex", gap: "5px", minWidth: "280px", alignItems: "flex-start"}}    
                >
                <img 
                    className="createWorksheet-configure-field-icon"
                    src={standardsIcon} alt="Align to Standards"/>
                    Align to Standards:
                <p style={{color: "var(--mid-light-grey)", alignSelf: "flex-start", fontSize: "14px", margin: "0", paddingTop: "3px"}}>
                    (optional)
                </p>
                <button type="button" className="alignToStandards-arrow-toggle-container" onClick={() => setExpanded(!expanded)}>
                    <img className={`alignToStandards-arrow ${expanded ? 'up' : 'down'}`} src={expandIcon} alt="Toggle Expand"/>
                </button>
                </div>
                <div className="alignToStandards-selectedStandards-container">
                    { generalInfo.standards.map((standard, index) => (
                        <div className="alignToStandards-selectedStanard-option"
                            key={`selectedStandard-${standard.standard_code}`}>
                            <p className="alignToStandards-selectedStanard-text">{standard.standard_code}</p>
                            <button 
                                onClick={() => handleRemoveStandard(index)}
                                style={{padding: "0", display: "flex", alignItems: "center"}} type="button">
                                    <img style={{paddingBottom: "1px"}} src={deleteIcon} alt="remove"/>
                            </button>
                        </div>
                    )) }
                </div>
            </div>
                
            { expanded && 
                <>
                <div className="alignToStandards-dropdown-row">
                    <div className="alignToStandards-dropdown-item">
                        <label
                            className="alignToStandards-fieldLabel"
                            htmlFor="alignToStandards-standardType">
                                Choose a Standard Type:</label>
                        <select 
                            className="createWorksheet-configure-info-dropdown"
                            id="alignToStandards-standardType">
                            {/* <option disabled selected value> -- select an option -- </option> */}
                            <option>Common Core ELA</option>
                            {/* Add more options as needed */}
                        </select>
                    </div>
                    <div className="alignToStandards-dropdown-item">
                        <label
                            className="alignToStandards-fieldLabel"
                            htmlFor="alignToStandards-grade">
                                Choose a Grade Level:</label>
                        <select 
                            className="createWorksheet-configure-info-dropdown"
                            id="alignToStandards-grade"
                            onChange={handleGradeChange}>
                            <option value=''> -- select an option -- </option>
                            { 
                                gradeLevels.map((grade) => (
                                    <option 
                                        key={`standards-grade-${grade.id}`}
                                        value={grade.id}>
                                        {grade.name}
                                    </option>))
                            }
                            
                        </select>
                    </div>
                    <div className="alignToStandards-dropdown-item">
                        <label
                            className="alignToStandards-fieldLabel"
                            htmlFor="alignToStandards-search"
                            >
                                Search:
                        </label>
                        <input 
                            type="text"
                            id="alignToStandards-search" 
                            className="createWorksheet-configure-info-textInput"
                            placeholder="Search by standard code or description"
                            style={{minWidth: "300px", paddingRight: "35px"}}
                            autoComplete="off"
                            ref={searchInputRef}
                            onChange={handleSearchChange}/>
                        { /* Horrific way of doing this but will fix this later */}
                        <img 
                            src={searchIcon} alt="Search"
                            style={{marginLeft: "-30px", marginBottom: "-7px"}}/>
                    </div>
                </div>
                <div className="alignToStandards-options-container">
                    {   filteredStandards.length > 0 ? 
                        filteredStandards.map((standard) => (
                            <React.Fragment key={standard.standard_code}>
                            <div 
                                className="alignToStandards-option-item"
                                key={`standard-${standard.code}`}>
                                <h4 
                                    className="alignToStandards-option-title"
                                    style={{margin: "0"}}>
                                    {standard.standard_code}
                                </h4>
                                <p 
                                    className="alignToStandards-option-desc"
                                    style={{margin: "0"}}>
                                    {standard.description}
                                </p>
                                <input 
                                    className="alignToStandards-option-check"
                                    onChange={handleCheckboxChange}
                                    type="checkbox" id={standard.code} value={standard.standard_code}
                                    checked={generalInfo.standards.some((s) => s.standard_code === standard.standard_code)}/>
                            </div>
                            <div className="alignToStandard-line-seperator"/>
                            </React.Fragment>
                        )) :
                        <p style={{fontSize: "14px", margin: "5px"}}>
                            No results for your search.
                        </p>
                    }
                </div>
                </>
            }
        </div>
        
  );
}

AlignToStandards.propTypes = {
    generalInfo:generalInfoPropType,
    setGeneralInfo: PropTypes.func,
};

export default AlignToStandards;
