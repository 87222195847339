import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import parse, { domToReact } from 'html-react-parser';
import { Document, Page, Image, Text, View } from '@react-pdf/renderer';
import { createStylesPDF, createStylesHTML, applyStyles } from './CreateStyles';


const DocumentWrap = forwardRef(({ isPdf, children }, ref) =>
    isPdf ? (
      <Document>{children}</Document>
    ) : (
      <div className='html-Document' ref={ref}>
        {children}
      </div>
    )
);

function PageWrap({ isPdf, size, elementKey, styleProps, children }) {

    const styles = isPdf ? createStylesPDF(styleProps) : createStylesHTML(styleProps);

    return isPdf
        ? <Page size={size} style={styles.page} key={elementKey}>{children}</Page>
        : <div className='html-Page' data-style-name="page" style={styles.page} id={elementKey || undefined}>{children}</div>
}


const ViewWrap = forwardRef(({ isPdf, styleProps, styleName, elementKey, children }, ref) => {
    const styles = isPdf ? createStylesPDF(styleProps) : createStylesHTML(styleProps);
    const isFixed = isPdf && (styleName === "header" || styleName === "footer");
    const isWrap = isPdf && !(styleName === "questionContainer");

    return isPdf ? (
      <View style={styles[styleName]} key={elementKey} fixed={isFixed} wrap={isWrap}>
        {children}
      </View>
    ) : (
      <div className='html-View' style={styles[styleName]} data-style-name={styleName} id={elementKey || undefined} ref={ref}>
        {children}
      </div>
    );
});

function BoldWrap({ isPdf, children }) {
    return isPdf
        ? <Text style={{fontWeight: 'bold', display: 'inline'}}>{children}</Text>
        : <strong>{children}</strong>
}

function ItalicWrap({ isPdf, children }) {
    return isPdf
        ? <Text style={{ display: 'inline'}}>{children}</Text>
        : <i>{children}</i>
}

function NewLineWrap({ isPdf }) {
    return isPdf
        ? <Text>{'\n'}</Text>
        : <br/>
}


const TextWrap = forwardRef(({ isPdf, styleProps, styleNames, elementKey, children }, ref) => {
    const pdfStyles = createStylesPDF(styleProps);
    const htmlStyles = createStylesHTML(styleProps);
    const styles = isPdf ? pdfStyles : htmlStyles;

    const appliedStyles = applyStyles({
        styles,
        styleNames,
        isPdf
    });

    return isPdf
        ? <Text style={appliedStyles} key={elementKey}>{children}</Text>
        : <p
            style={appliedStyles}
            className='html-Text'
            data-style-names={styleNames.join(' ')}
            id={elementKey || undefined}
            ref={ref} // Attach the ref here
          >
            {children}
          </p>;
});

const EmbeddedTextWrap = forwardRef(({ isPdf, styleProps, styleName, elementKey, children }, ref) => {

    const styles = isPdf ? createStylesPDF(styleProps) : createStylesHTML(styleProps);

    return isPdf
        ? <Text key={elementKey}>{children}</Text>
        : <div
            className='html-EmbeddedText'
            style={styles[styleName]}
            data-style-name={styleName}
            id={elementKey || undefined}
            ref={ref} // Attach the ref here
          >
            {children}
          </div>;
});

function ImageWrap({ isPdf, styleProps, styleName, src, elementKey }) {
    const pdfStyles = createStylesPDF(styleProps);
    const htmlStyles = createStylesHTML(styleProps);
    const styles = isPdf ? pdfStyles : htmlStyles;

    return isPdf
        ? <Image src={src} style={styles[styleName]} key={elementKey} />
        : <img src={src} style={styles[styleName]} className='html-Image' data-style-name={styleName} id={elementKey || undefined} alt="" />

};


function UpdatedComponents({ updatedHtml, isPdf, styleProps = { fontSize: 12, lineSpacing: 1.5 } }) {
    const options = {
        replace: domNode => {

            if (isPdf && domNode.attribs.class === "whole-worksheet") return domToReact(domNode.children, options);

            if (domNode.name === 'div' && domNode.attribs.class === 'html-Document') {
                return <DocumentWrap isPdf={isPdf}>{domToReact(domNode.children, options)}</DocumentWrap>;
            }
            if (domNode.name === 'div' && domNode.attribs.class === 'html-Page') {
                return <PageWrap isPdf={isPdf} styleProps={styleProps}>{domToReact(domNode.children, options)}</PageWrap>;
            }
            if (domNode.name === 'div' && domNode.attribs.class === 'html-View') {
                const styleName = domNode.attribs['data-style-name'];

                const firstChild = domNode.children[0];
                if (firstChild && firstChild.attribs && firstChild.attribs.id && firstChild.attribs.id.startsWith('answerSegment')) {
                    const styleNames = firstChild.attribs['data-style-names'].split(' ');

                    return (<ViewWrap isPdf={isPdf} styleProps={styleProps} styleName={styleName}>
                        <TextWrap isPdf={isPdf} styleProps={styleProps} styleNames={styleNames}>{domToReact(domNode.children, options)}
                        </TextWrap></ViewWrap>);
                }

                return <ViewWrap isPdf={isPdf} styleProps={styleProps} styleName={styleName}>{domToReact(domNode.children, options)}</ViewWrap>;
            }
            if (domNode.name === 'div' && domNode.attribs.class === 'html-EmbeddedText') {
                const styleName = domNode.attribs['data-style-name'];

                return <EmbeddedTextWrap isPdf={isPdf} styleProps={styleProps} styleName={styleName}>{domToReact(domNode.children, options)}</EmbeddedTextWrap>;
            }
            if (domNode.name === 'p' && domNode.attribs.class === 'html-Text') {
                const styleNames = domNode.attribs['data-style-names'].split(' ');
                
                const content = domNode.children.map((child) => {
                    if (child.type === 'text') {
                        return child.data; // Return plain text
                    }
                    if (child.type === 'tag') {
                        // Handle specific tags <strong>, <i>, etc.
                        if (child.name === 'strong') {
                            return <BoldWrap isPdf={isPdf}>{child.children[0]?.data || ''}</BoldWrap>;
                        } 
                        if (child.name === 'i') {
                            return <ItalicWrap isPdf={isPdf}>{child.children[0]?.data || ''}</ItalicWrap>;
                        }
                    }
                    return null; // Return null for any unsupported types
                });

                return <TextWrap isPdf={isPdf} styleProps={styleProps} styleNames={styleNames}>{content}</TextWrap>;
            }
            if (domNode.name === 'img' && domNode.attribs.class === 'html-Image') {
                const { src, 'data-style-name': styleName } = domNode.attribs;
                return <ImageWrap isPdf={isPdf} styleProps={styleProps} styleName={styleName} src={src} />;
            }

            return isPdf ? <Text>null</Text> : null;
        }
    };
    const p = parse(updatedHtml, options);

    console.log(p)

    return p;
    ;

}


const stylePropsShape = PropTypes.shape({
    fontSize: PropTypes.number,
    lineSpacing: PropTypes.number,
    // Add other relevant style properties here
});

UpdatedComponents.propTypes = {
    updatedHtml: PropTypes.string.isRequired,
    isPdf: PropTypes.bool.isRequired,
    styleProps: stylePropsShape.isRequired,
};

DocumentWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
};

PageWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // Size can be string or object depending on react-pdf settings
    elementKey: PropTypes.string,
    styleProps: stylePropsShape.isRequired, // Assuming styleProps is an object
    children: PropTypes.node.isRequired,
};

BoldWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
}

ItalicWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
}

NewLineWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
}

ViewWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    styleProps: stylePropsShape.isRequired,
    styleName: PropTypes.string,
    elementKey: PropTypes.string,
    children: PropTypes.node.isRequired,
};

EmbeddedTextWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    styleProps: stylePropsShape.isRequired,
    styleName: PropTypes.string,
    elementKey: PropTypes.string,
    children: PropTypes.node.isRequired,
};

TextWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    styleProps: stylePropsShape.isRequired,
    styleNames: PropTypes.arrayOf(PropTypes.string).isRequired, // Updated to handle an array of style names
    elementKey: PropTypes.string,
    children: PropTypes.node.isRequired,
};


ImageWrap.propTypes = {
    isPdf: PropTypes.bool.isRequired,
    styleProps: stylePropsShape.isRequired,
    styleName: PropTypes.string,
    src: PropTypes.string.isRequired,
    elementKey: PropTypes.string,
};

export { DocumentWrap, PageWrap, ViewWrap, TextWrap, ImageWrap, EmbeddedTextWrap, BoldWrap, ItalicWrap, UpdatedComponents, NewLineWrap };