import React from "react";
import { doc, setDoc } from 'firebase/firestore';
import { useUser, useFirestore } from 'reactfire';
import { useNavigate } from 'react-router-dom';

import Navbar from "../../components/NavBar/NavBar";
import Feedback from "./components/Feedback";

import './Help.css';

const completed = [
    { dashboard_tutorial: true },
    { choose_template_tutorial: true },
    { choose_method_tutorial: true },
    { create_worksheet_tutorial: true },
    { edit_worksheet_tutorial: true }
];
function Help() {

	const { data: user } = useUser();
	const firestore = useFirestore();

	const navigate = useNavigate();

	const restartTutorial = async () => {
		if (user) {
			try {
				// Create an object with all tutorial fields set to false
				const tutorialFields = completed.reduce((acc, tutorial) => {
					const key = Object.keys(tutorial)[0];
					acc[key] = false;
					return acc;
				}, {});
	
				const userDocRef = doc(firestore, 'users', user.uid);
				await setDoc(userDocRef, tutorialFields, { merge: true });

				// Redirect to the homepage
                navigate('/home');
				console.log("All tutorial fields set to false.");
			} catch (error) {
				console.error("Error setting tutorial fields to false:", error);
			}
		}
	};
	

	return (
		
		<div className="base-container">
			<Navbar/>
			<div className="base-content-container">
				<div className="help-container">
					<h1 className="help-title">Help & Feedback</h1>
					<div
						style={{display: "flex", flexDirection: "row", gap: "30px"}}
					>
						<div
							style={{display: "flex", flexDirection: "column", flex: "3"}}
						>
							<Feedback />
						</div>
						<div
							style={{display: "flex", flexDirection: "column", flex: "2"}}
						>
							<section className="help-section restartTutorial">
								<h2 className="help-section-title">
									Restart Tutorial
								</h2>
								<p className="help-section-subtitle">
									Want to revisit the onboarding steps? Start the tutorial from the beginning to get a refresher!
								</p>
								<button 
									type="button" 
									className="help-restartTutorial-button"
									onClick={restartTutorial}
								>
									Restart Tutorial
								</button>
							</section>

							<section className="help-section">
								<h2 className="help-section-title">Contact Us</h2>
								<p className="help-section-subtitle">If you have any questions, feel free to reach out at:</p>
								<a href="mailto:adrian@wiz-lab.co">adrian@wiz-lab.co</a><br/>
								<a href="mailto:katia@wiz-lab.co">katia@wiz-lab.co</a>
							</section>
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
	);
}

export default Help;
