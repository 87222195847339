import React from 'react';
import PropTypes from 'prop-types';

import '../PreviewWorksheet.css';

import Dropdown from '../../../../components/Dropdown/Dropdown';

function DeleteDropdown({ worksheetIndex, deleteWs }) {

    return (
        <Dropdown buttonText="Delete" className="previewWorksheet-dropbtn" id="delete">
            <button
                type="button"
                className="previewWorksheet-dropdown-option"
                onClick={() => {
                    deleteWs(worksheetIndex);
                }}
            >
                Delete this Worksheet Version
            </button>
        </Dropdown>
    );
};

DeleteDropdown.propTypes = {
    worksheetIndex: PropTypes.number,
    deleteWs: PropTypes.func
}

export default DeleteDropdown;