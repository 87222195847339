import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import "./YourActivityCard.css";
import actionIcon from '../../../../../assets/Dashboard/YourActivities/actionIcon.svg';
import { singleWorksheetDataPropType } from '../../../../CreateWorksheet/CreateWorksheetConstants'
import PreviewWorksheet from "../../../../CreateWorksheet/SharedComponents/PreviewWorksheet";
import Dropdown from "../../../../../components/Dropdown/Dropdown";



const YourActivityCard = React.memo(({ activityData, deleteSet }) => (

	<div className="yourActivityCard-container">
		<Link to={`/view-worksheet?id=${activityData.id}`}>

			<div className="yourActivityCard-thumbnail">

				{Array.from({ length: activityData.num_sheets }, (_, i) => (
					<div
						className="yourActivityCard-mini"
						style={
							{
								marginLeft: `${14}%`, marginTop: `${15 + i * (20 / activityData.num_sheets)}%`,
								transform: `rotate(${0 + i * (10 / activityData.num_sheets)}deg)`, zIndex: (activityData.num_sheets - i),
								filter: `brightness(${100 - i * 2}%)`, height: '2000px'
							}}
						key={`yourActivityCard-${activityData.id}-sheetPreview-${i}`}
					>
						<PreviewWorksheet worksheetIndex={-1} worksheetData={activityData.thumbnail} enableExport={false} full />
					</div>
				))}

			</div>
		</Link>
		<div className="yourActivityCard-info">

			<div className="yourActivityCard-description">
				<Link to={`/view-worksheet?id=${activityData.id}`}>

					<h3 className="yourActivityCard-title">{activityData.activity_name}</h3>
					<p className="yourActivityCard-lastEdited">
						Last Edited: {activityData.last_edited}
					</p>
				</Link>
			</div>
			<Dropdown buttonText="..." buttonIcon={<img className="yourActivityCard-action" src={actionIcon} alt="Action Dropdown" />} 
				className="yourActivityCard-action" isUp>
				<button
					type="button"
					onClick={() => deleteSet(activityData.id)}
				>
					Delete Set
				</button>
			</Dropdown>
		</div>
	</div>
))

YourActivityCard.propTypes = {
	activityData: PropTypes.shape({
		id: PropTypes.string,
		activity_name: PropTypes.string,
		num_sheets: PropTypes.number,
		thumbnail: singleWorksheetDataPropType,
		last_edited: PropTypes.string,
	}),
	deleteSet: PropTypes.func
};

export default YourActivityCard;
