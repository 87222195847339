import React from "react";
import "./MyLab.css";
import Navbar from "../../components/NavBar/NavBar";
import YourActivitiesDashboard from "../Dashboard/components/YourActivitiesSection/YourActivitesDashboard";


function MyLab() {
	return (
		<div className="base-container">
			<Navbar/>
			<div className="base-content-container">
				<div className="myLab-container">
					<YourActivitiesDashboard/>
				</div>
			</div>
		</div>
	);
}

export default MyLab;
