import React, { useState } from "react";
import PropTypes from 'prop-types';

import { closestCorners, DndContext, MeasuringStrategy } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { v4 as uuidv4 } from 'uuid';

import SortableItem from "./components/SortableItem";
import "./EditWorksheet.css";
import { singleWorksheetDataPropType } from "../../CreateWorksheetConstants";

import draggableIcon from "../../../../assets/CreateWorksheet/draggableIcon.svg";
import deleteIcon from "../../../../assets/CreateWorksheet/deleteIcon.svg";

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */

function EditWorksheet({ worksheetIndex, worksheetData, setWorksheetData }) {

    const [removeMode, setRemoveMode] = useState(false);
    const [addMode, setAddMode] = useState(false);

    worksheetData.questions = worksheetData.questions.map((question) => {
        if (!question.id) {
            // Only assign an ID if it doesn't already have one
            return {
                ...question,
                id: uuidv4(), // Generate a unique id
            };
        }
        return question; // Keep the existing ID if it already has one
    });

    function handleAddQuestion(questionType) {
        const type = questionType
        const numLines = 1

        // Handles reading comp's immovable text box 'question'
        const newQuestions = (worksheetData.worksheet_type !== "reading comprehension") ? [
            {
                id: uuidv4(),
                question_type: `${type}`,
                question_text: '',
                correct_answer: ' ',
                incorrect_answers: ['', '', ''],
                num_lines: numLines
            },
            ...worksheetData.questions
        ] :
            [
                ...worksheetData.questions.slice(0, 1), // All elements before the second index
                {
                    id: uuidv4(),
                    question_type: `${type}`,
                    question_text: '',
                    correct_answer: ' ',
                    incorrect_answers: ['', '', ''],
                    num_lines: numLines
                }, // The new element at the second index
                ...worksheetData.questions.slice(1) // All elements from the second index onwards
            ];

        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions = newQuestions;
            // console.log(newWorksheetData);
            return newWorksheetData;
        });
    }

    function handleRemoveQuestion(index) {
        // Trigger the animation by updating the state
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            const newQuestions = Array.from(newWorksheetData[worksheetIndex].questions);
            newQuestions.splice(index, 1);
            newWorksheetData[worksheetIndex].questions = newQuestions;
            return newWorksheetData;
        });
    }

    function handleRemove() {
        setRemoveMode(!removeMode);
        // console.log(removeMode);
    }

    function handleAdd() {
        setAddMode(!addMode);
        // console.log(removeMode);
    }

    const handleAddLine = (qN) => {
        setWorksheetData((prevData) => {
          const newWorksheetData = [...prevData];
          const currentNumLines = newWorksheetData[worksheetIndex].questions[qN].num_lines || 0;
        //   console.log('numLines before: ', currentNumLines);
          newWorksheetData[worksheetIndex].questions[qN].num_lines = currentNumLines + 1;
        //   console.log('numLines after: ', newWorksheetData[worksheetIndex].questions[qN].num_lines);
          return newWorksheetData;
        });
    };

      const handleRemoveLine = (qN) => {
        setWorksheetData((prevData) => {
          const newWorksheetData = [...prevData];
          const currentNumLines = newWorksheetData[worksheetIndex].questions[qN].num_lines || 0;
        //   console.log('numLines before: ', currentNumLines);
          newWorksheetData[worksheetIndex].questions[qN].num_lines = currentNumLines > 0 ? currentNumLines - 1 : 0;
        //   console.log('numLines after: ', newWorksheetData[worksheetIndex].questions[qN].num_lines);
          return newWorksheetData;
        });
    };

    function handleQuestionChange(e, questionIndex) {
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions[questionIndex].question_text = e.target.value;
            return newWorksheetData;
        });
    }

    function handleCorrectAnswerChange(e, questionIndex) {
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions[questionIndex].correct_answer = e.target.value;
            return newWorksheetData;
        });
    }

    function handleIncorrectAnswerChange(e, questionIndex, answerIndex) {
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions[questionIndex].incorrect_answers[answerIndex] = e.target.value;
            return newWorksheetData;
        });
    }

    function handleGlossaryChange(e, questionIndex, answerIndex) {
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].questions[questionIndex].glossary_items[answerIndex] = e.target.value;
            return newWorksheetData;
        });
    }

    function handleGlossaryDelete(questionIndex, answerIndex) {
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            // Remove the item at the specified index
            newWorksheetData[worksheetIndex].questions[questionIndex].glossary_items.splice(answerIndex, 1);
            return newWorksheetData;
        });
    }

    function handleGlossaryAdd(questionIndex) {
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            // Append a new glossary item (empty string or default value) at the end of the glossary_items array
            newWorksheetData[worksheetIndex].questions[questionIndex].glossary_items.push("");
            return newWorksheetData;
        });
    }
    
    const handleOnDragEnd = (event) => {

        const { active, over } = event;

        // console.log("active:", active);
        // console.log("over:", over);

        if (active.id !== over.id) {

            // Handle text passage box being immovable
            if (worksheetData.worksheet_type === "reading comprehension" && over.data.current.sortable.index === 0) return;

            console.log("set new data");

            setWorksheetData((prevData) => {
                const oldIndex = prevData[worksheetIndex].questions.findIndex(item => item.id.toString() === active.id);
                const newIndex = prevData[worksheetIndex].questions.findIndex(item => item.id.toString() === over.id);

                const newQuestions = arrayMove(prevData[worksheetIndex].questions, oldIndex, newIndex);
                const newWorksheetData = [...prevData];
                newWorksheetData[worksheetIndex].questions = newQuestions;

                return newWorksheetData;
            });
        }
    }

    const measuringConfig = {
        droppable: {
            strategy: MeasuringStrategy.Always
        }
    };

    const handleVersionTitleChange = (event) => {
        setWorksheetData((prevData) => {
            const newWorksheetData = [...prevData];
            newWorksheetData[worksheetIndex].worksheet_title = event.target.value;
            return newWorksheetData;
        });
	};

    return (
        <DndContext onDragEnd={handleOnDragEnd} collisionDetection={closestCorners} modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]} measuring={measuringConfig}>
            <div className="editWorksheet-container">
                <div 
                    className="editWorksheet-header"
                    style={{gap: "20px"}}
                >
                    <div>
                        <h1 className="editWorksheet-title">Edit Worksheet Title:</h1>
                        <p className="editWorksheet-subTitle">Edit this worksheet version&apos;s title.</p>
                    </div>
                    <input
                        type="text"
                        className="differentiationGroups-group-textInput"
                        placeholder={worksheetData.worksheet_title}
                        value={worksheetData.worksheet_title}
                        onChange={(e) => handleVersionTitleChange(e)}
                        style={{flex: "2"}}
                    />
                </div>
                <div className="editWorksheet-header">
                    <div>
                        <h1 className="editWorksheet-title">Edit Questions</h1>
                        <p className="editWorksheet-subTitle">Edit the generated questions and preview.</p>
                    </div>
                    <div 
                        id="editWorksheet-addAndRemove"
                        style={{ display: 'flex', flexDirection: 'row' }}
                    >
                        <button
                            type="button"
                            className="editWorksheet-button"
                            onClick={worksheetData.available_types.length === 1 ? () => handleAddQuestion(worksheetData.available_types[0]) : handleAdd}
                        > Add </button>
                        <button
                            type="button"
                            className={`editWorksheet-button ${removeMode ? 'active' : ''}`}
                            onClick={handleRemove}
                            id="removeButton"
                        >Remove</button>
                    </div>

                </div>

                <div className={`editWorksheet-addDropDown-${addMode ? "active" : "inactive"}`}>
                    {
                        // Maps available question types to their add buttons IF question types length greater than 1
                        worksheetData.available_types.map((type, index) => (
                            <button
                                type="button"
                                key={`addQuestionType-${index + 1}`}
                                className="editWorksheet-button"
                                onClick={() => handleAddQuestion(type)}
                            > {type} </button>
                        ))
                    }
                </div>

                <div className="editWorksheet-questions-container">

                    <SortableContext items={worksheetData.questions.map(q => q.id.toString())} strategy={verticalListSortingStrategy}>
                        {worksheetData.questions.map((question, questionIndex) => (
                            <SortableItem
                                key={question.id.toString()}
                                id={question.id}
                                removeMode={removeMode}
                                disabled={(question.question_type && question.question_type === "text")}
                            >
                                {({ listeners, removeMode }) => (
                                    <div className="editWorksheet-listItem-container">
                                        {
                                            removeMode && (question.question_type !== "text") && (
                                                <button
                                                    className="editWorksheet-listItem-handle-remove"
                                                    type="button"
                                                    onClick={() => handleRemoveQuestion(questionIndex)}>✖</button>
                                            )
                                        }
                                        {
                                            !removeMode && (question.question_type !== "text") && (
                                                <div className="editWorksheet-listItem-handle" {...listeners}>
                                                    <img
                                                        src={draggableIcon}
                                                        alt="Draggable"
                                                        style={{ width: "20px", height: "40px" }}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            (question.question_type === "text") && (
                                                <div className="editWorksheet-listItem-handle-disabled" {...listeners} />
                                            )
                                        }
                                        <div
                                            className="editWorksheet-question-container"
                                            key={`question-${question.id + 1}`}
                                        >
                                            { question.question_type === "glossary" && 
                                                <label
                                                    className="editWorksheet-question-label question"
                                                    htmlFor="editWorksheet-question-questionText"
                                                    style={{paddingBottom: "10px"}}
                                                >
                                                    Glossary:
                                                </label>
                                            }
                                            { question.question_type !== "glossary" && 
                                            (
                                                <>
                                                <label
                                                    className="editWorksheet-question-label question"
                                                    htmlFor="editWorksheet-question-questionText"
                                                >
                                                    {(worksheetData.worksheet_type !== "reading comprehension") && <>Question {questionIndex + 1}:</>}
                                                    {(worksheetData.worksheet_type === "reading comprehension" && question.question_type !== "text") && <>Question {questionIndex}:</>}
                                                </label>
                                                <textarea
                                                    id="editWorksheet-question-questionText"
                                                    name="editWorksheet-question-questionText"
                                                    className="editWorksheet-question-textArea"
                                                    value={question.question_text}
                                                    onChange={(e) => handleQuestionChange(e, questionIndex)}
                                                    style={{ resize: "both", overflow: "auto", 
                                                                height: `${question.question_type && question.question_type === "text" ? "200px" : ""}`,
                                                                minHeight: "60px"}}
                                                    rows="1" cols="40"
                                                />
                                                </>
                                            )}
                                            {(!question.question_type || (question.question_type !== "text" && question.question_type !== "glossary")) &&
                                                <>
                                                    <label
                                                        className="editWorksheet-question-label"
                                                        htmlFor="editWorksheet-question-correctAns"
                                                    >
                                                        Correct Answer:
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        className="editWorksheet-question-textInput"
                                                        id="editWorksheet-question-correctAns"
                                                        name="editWorksheet-question-correctAns"
                                                        value={question.correct_answer}
                                                        onChange={(e) => handleCorrectAnswerChange(e, questionIndex)}
                                                        style={{ resize: "both", overflow: "auto", minHeight: "20px" }}
                                                        rows="1" cols="40"
                                                    />
                                                </>
                                            }
                                            {(question.question_type === "multiple choice") && (
                                                <>
                                                    <p className="editWorksheet-question-label">Incorrect Answers:</p>
                                                    <div className="editWorksheet-question-wrongAns-container">
                                                        {question.incorrect_answers.map((ans, answerIndex) => (
                                                            <textarea
                                                                type="text"
                                                                className="editWorksheet-question-textInput"
                                                                id={`editWorksheet-question-wrongAns-${answerIndex + 1}`}
                                                                key={`editWorksheet-question-wrongAns-${answerIndex + 1}`}
                                                                name="editWorksheet-question-wrongAns"
                                                                value={ans}
                                                                onChange={(e) => handleIncorrectAnswerChange(e, questionIndex, answerIndex)}
                                                                style={{ resize: "both", overflow: "auto", minHeight: "20px" }}
                                                                rows="1" cols="50"
                                                            />
                                                        ))}
                                                    </div>
                                                </>
                                            )}
            
                                            {(question.question_type === "glossary") && (
                                                <div className="editWorksheet-question-wrongAns-container">
                                                    {question.glossary_items.map((ans, answerIndex) => (
                                                        <div key={`glossary-item-${answerIndex + 1}`} className="editWorksheet-glossary-item-row">
                                                            <textarea
                                                                type="text"
                                                                className="editWorksheet-question-textInput"
                                                                id={`editWorksheet-question-wrongAns-${answerIndex + 1}`}
                                                                name="editWorksheet-question-wrongAns"
                                                                value={ans}
                                                                onChange={(e) => handleGlossaryChange(e, questionIndex, answerIndex)}
                                                                style={{ resize: "both", overflow: "auto", minHeight: "20px" }}
                                                                rows="1" cols="40"
                                                            />
                                                            <button
                                                                type="button"
                                                                className="delete-glossary-item-button"
                                                                style={{color: "black", padding: "5px"}}
                                                                onClick={() => handleGlossaryDelete(questionIndex, answerIndex)}
                                                            >
                                                                <img src={deleteIcon} alt="Remove"/>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {(question.question_type === "glossary") &&
                                                <div className="editWorksheet-listItem-spaceAdder">
                                                    <p className="editWorksheet-question-label">Add Item:</p>
                                                    <button
                                                        className="editWorksheet-listItem-spaceAdder-button"
                                                        type="button"
                                                        onClick={() => handleGlossaryAdd(questionIndex)}
                                                    > + </button>
                                                </div>
                                            }

                                            {(question.question_type !== "text") &&
                                                <div className="editWorksheet-listItem-spaceAdder">
                                                    <p className="editWorksheet-question-label">Add Spacing:</p>
                                                    <button
                                                        className="editWorksheet-listItem-spaceAdder-button"
                                                        type="button"
                                                        onClick={() => handleAddLine(questionIndex)}
                                                    > + </button>
                                                    <button
                                                        className="editWorksheet-listItem-spaceAdder-button"
                                                        type="button"
                                                        onClick={() => handleRemoveLine(questionIndex)}
                                                    > - </button>
                                                </div>
                                            }
                                        </div>

                                        

                                    </div>
                                )}
                            </SortableItem>
                        )
                        )}
                    </SortableContext>
                </div>
            </div>
        </DndContext>
    );
}

EditWorksheet.propTypes = {
    worksheetIndex: PropTypes.number,
    setWorksheetData: PropTypes.func,
    worksheetData: singleWorksheetDataPropType,
};

export default EditWorksheet;
