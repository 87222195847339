import React from "react";
import PropTypes from 'prop-types';
import "./TemplatesDashboard.css";

// import collabIcon from "../../../../assets/Dashboard/Templates/collabIcon.png"

import multipleChoiceIcon from '../../../../assets/Dashboard/ActivityIcons/multipleChoiceIcon.png';
import fillInTheBlankIcon from '../../../../assets/Dashboard/ActivityIcons/fillInTheBlankIcon.png';
import readingComprehensionIcon from '../../../../assets/Dashboard/ActivityIcons/readingComprehensionIcon.png';
import writtenAnswerIcon from '../../../../assets/Dashboard/ActivityIcons/writtenAnswerIcon.png';
import quizIcon from '../../../../assets/Dashboard/ActivityIcons/quizIcon.png';
// import arrowIcon from "../../../../assets/Dashboard/Templates/arrowRight.svg";
import pencilIcon from "../../../../assets/Dashboard/Templates/pencilIcon.svg"


function TemplatesDashboard({ openPopup }) {
	return (
		<div className="templatesDashboard-container">
			{/* Top Banner to Create Worksheet */}
			<div 
				className="templatesDashboard-row createWorksheetSection"
				id="dashboard-createWorksheetBanner"
			>
				<div 
					className="templatesDashboard-createWorksheetSection-info"
					id="dashboard-createWorksheetBannerInfo"
				>
					<h1 className="templatesDashboard-createWorksheetSection-title">Create Personalized Worksheets</h1>
					<p className="templatesDashboard-createWorksheetSection-description">To get started with creating a worksheet, import a physical worksheet or pdf of any learning content (ex: textbook pages, article).</p>
				</div>
				<div className="templatesDashboard-createWorksheetSection-buttons">
					<button 
						type="button" 
						className="templatesDashboard-createWorksheetSection-button"
						onClick={() => openPopup(true, "")}
						style={{"padding": "0"}}>
						<img className="templatesDashboard-createWorksheetSection-button-icon" src={pencilIcon} alt="Import Existing Worksheet"/>
						<h2 className="templatesDashboard-createWorksheetSection-button-text">Click Here To Start!</h2>
					</button>
				</div>
			</div>
			{/* or start from our Templates section */}
			<div className="templatesDashboard-row templatesSection">
				<div className="templatesDashboard-templatesSection-header">
					<h2 className="templatesDashboard-templatesSection-heading">or start from our library</h2>
					
					{/* <div className="templatesDashboard-templatesSection-seeTemplates-container">
						<p className="templatesDashboard-templatesSection-seeTemplates-text">See all templates</p>
						<img className="templatesDashboard-templatesSection-seeTemplates-icon" src={arrowIcon} alt="See all templates" />
					</div> */}
				</div>

				<div className="templatesDashboard-templatesSection-cards">
					<div className="templatesDashboard-card">
						<button 
							type="button" 
							onClick={() => openPopup(false, "multiple choice")}
							style={{"padding": "0"}}>
						<img 
							className="templatesDashboard-card-img" 
							src={multipleChoiceIcon} 
							alt="Create a Knowledge Check" 
						/>
						<h2 className="templatesDashboard-card-title">Multiple Choice</h2>
						<p className="templatesDashboard-card-description">Ideal for quick assessments and reinforcing key concepts.</p>
						</button>
					</div>
					<div className="templatesDashboard-card">
						<button 
							type="button" 
							onClick={() => openPopup(false, "fill in the blank")}
							style={{"padding": "0"}}>
							<img 
								className="templatesDashboard-card-img" 
								src={fillInTheBlankIcon} 
								alt="Create a Worksheet"
							/>
							<h2 className="templatesDashboard-card-title">Fill in the Blank</h2>
							<p className="templatesDashboard-card-description">Reinforce learning and encourage recall with fill-in-the-blank questions.</p>
						</button>
					</div>
					<div className="templatesDashboard-card">
						<button 
							type="button" 
							onClick={() => openPopup(false, "reading comprehension")}
							style={{"padding": "0"}}>
							<img 
								className="templatesDashboard-card-img" 
								src={readingComprehensionIcon} 
								alt="Create a Worksheet"
							/>
							<h2 className="templatesDashboard-card-title">Reading Comprehension</h2>
							<p className="templatesDashboard-card-description">Boost understanding with passages followed by comprehension questions.</p>
						</button>
					</div>
					<div className="templatesDashboard-card">
						<button 
							type="button" 
							onClick={() => openPopup(false, "written response")}
							style={{"padding": "0"}}>
							<img 
								className="templatesDashboard-card-img" 
								src={writtenAnswerIcon} 
								alt="Create a Worksheet"
							/>
							<h2 className="templatesDashboard-card-title">Written Response</h2>
							<p className="templatesDashboard-card-description">Perfect for open-ended questions and creative responses.</p>
						</button>
					</div>
					<div className="templatesDashboard-card">
						<button 
							type="button" 
							onClick={() => openPopup(false, "quiz worksheet")}
							style={{"padding": "0"}}>
							<img 
								className="templatesDashboard-card-img" 
								src={quizIcon} 
								alt="Create a Worksheet"
							/>
							<h2 className="templatesDashboard-card-title">Mixed Question Set</h2>
							<p className="templatesDashboard-card-description">All in one multiple choice, fill-in-the-blank, and short response questions.</p>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

TemplatesDashboard.propTypes = {
    openPopup: PropTypes.func,
};


export default TemplatesDashboard;
