import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import "./CreateActivityPopup.css";

import differentiateIcon from '../../../../assets/Dashboard/CreateActivityPopup/differentiateIcon.svg';
import bookIcon from '../../../../assets/Dashboard/CreateActivityPopup/bookIcon.png';
import scrollIcon from '../../../../assets/Dashboard/CreateActivityPopup/scrollIcon.png';
import newspaperIcon from '../../../../assets/Dashboard/CreateActivityPopup/newspaperIcon.png';

import multipleChoiceIcon from '../../../../assets/Dashboard/ActivityIcons/multipleChoiceIcon.png';
import fillInTheBlankIcon from '../../../../assets/Dashboard/ActivityIcons/fillInTheBlankIcon.png';
import readingComprehensionIcon from '../../../../assets/Dashboard/ActivityIcons/readingComprehensionIcon.png';
import writtenAnswerIcon from '../../../../assets/Dashboard/ActivityIcons/writtenAnswerIcon.png';
import quizIcon from '../../../../assets/Dashboard/ActivityIcons/quizIcon.png';

import exitIcon from '../../../../assets/shared/exitIcon-black.png';

function CreateActivityPopup({ closePopup, inputType, template, setInputType, setTemplate }) {
    const [showTemplates, setShowTemplates] = useState(template === "");
    const navigate = useNavigate();

    useEffect(() => {
        // Check if both `inputType` and `template` are not empty
        if (inputType !== "" && template !== "") {
            navigate('/create-worksheet', { state: { inputType, worksheetTemplate: template } });
        }
    }, [inputType, template, navigate]);

    return (
      <div className="popup-overlay">
        <div 
          className="popup"
          id="popupContainer"
        >
          <div className="popup-header"
            id="popupHeader"
          >
            <button type="button" onClick={closePopup} className="popup-close-button">
              <img src={exitIcon} alt="exit"/></button>
            <h2 className="popup-title">
              { showTemplates ? 
                "Choose Your Worksheet Template  🧩" : 
                "Choose Your Starting Content  📝"}
            </h2>
          </div>
          <div className="popup-body">
            <p className="popup-description">
              {showTemplates ? 
              "Pick the type of activity that best fits your lesson goals. Each template is adaptable for all grades!" : 
              "Select the type of material you want to import and transform into a customized worksheet. "}
            </p>
            <div className="popup-options-container">
                { template ==="" && 
                  <>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => {setTemplate("multiple choice"); setShowTemplates(false)}}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={multipleChoiceIcon} alt="Multiple Choice"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Multiple Choice</h2>
                        <p className="popup-tag template">Ideal for quick assessments and reinforcing key concepts.</p>
                      </div>
                  </button>

                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => {setTemplate("fill in the blank"); setShowTemplates(false);}}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={fillInTheBlankIcon} alt="Fill In The Blank"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Fill in the Blank</h2>
                        <p className="popup-tag template">Reinforce learning and encourage recall with fill-in-the-blank questions.</p>
                      </div>
                  </button>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => {setTemplate("reading comprehension"); setShowTemplates(false);}}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={readingComprehensionIcon} alt="Reading Comprehension"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Reading Comprehension</h2>
                        <p className="popup-tag template">Boost understanding with passages followed by comprehension questions.</p>
                      </div>
                  </button>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => {setTemplate("written response"); setShowTemplates(false);}}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={writtenAnswerIcon} alt="Written Response"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Written Response</h2>
                        <p className="popup-tag template">Perfect for open-ended questions and creative responses.</p>
                      </div>
                  </button>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => {setTemplate("quiz worksheet"); setShowTemplates(false);}}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={quizIcon} alt="Quiz Worksheet"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Mixed Question Set</h2>
                        <p className="popup-tag template">All in one multiple choice, fill-in-the-blank, and short response questions.</p>
                      </div>
                  </button>
                  </>
                }
                { !showTemplates &&
                  <>
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => setInputType("worksheet")}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={differentiateIcon} alt="Input an Existing Worksheet"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Worksheet</h2>
                        <p className="popup-tag import">Upload an existing worksheet to personalize for your students.</p>
                      </div>
                  </button>
                  
                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => setInputType("teaching resource")}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={newspaperIcon} alt="Generate from Reading"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Reading Excerpt</h2>
                        <p className="popup-tag import">Import a reading passage to create comprehension or analysis worksheets.</p>
                      </div>
                  </button>

                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => setInputType("teaching resource")}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={scrollIcon} alt="Generate from Lesson Plan"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Lesson Plan</h2>
                        <p className="popup-tag import">Turn a lesson plan into interactive and adaptable materials.</p>
                      </div>
                  </button>

                  <button 
                    type="button" 
                    className="popup-option" 
                    onClick={() => setInputType("teaching resource")}>
                      <div className="popup-icon-container">
                        <img className="popup-option-icon" src={bookIcon} alt="Generate from Textbook"/>
                      </div>
                      <div className="popup-option-title-container">
                        <h2 className="popup-option-title">Texbook Excerpt</h2>
                        <p className="popup-tag import">Use a section of a textbook to build questions or exercises.</p>
                      </div>
                  </button>
                  </>
                }
                
            </div>
          </div>
        </div>
      </div>
    );
}

CreateActivityPopup.propTypes = {
    closePopup: PropTypes.func,
    inputType: PropTypes.string,
    template: PropTypes.string,
    setInputType: PropTypes.func,
    setTemplate: PropTypes.func,
};

export default CreateActivityPopup;