import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { diffGroupsPropType } from '../../../CreateWorksheetConstants';

import "../DifferentiationGroups.css";

function ConfigureMLL ({ index, diffGroups, setDiffGroups, isDiffGroupValid }) {
    const [expanded, setExpanded] = useState(false);

    const handleToggleMLL = (expand) => {
        setExpanded(expand);

        if (!expand) {
            const newDiffGroups = [...diffGroups];
            const targetGroup = newDiffGroups[index]

            if (targetGroup === undefined) {
                console.log("Error: cannot find target group");
                return;
            }
            
            targetGroup.accommodations.MLL.active = expand;
            setDiffGroups(newDiffGroups);
        }
    }

    const handleAccomodationChange = (event) => {
        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index]

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }
        
        const accommodation = event.target.value;
        targetGroup.accommodations.MLL.type = accommodation;
        targetGroup.accommodations.MLL.active = true;
		setDiffGroups(newDiffGroups);
	};

    const handleLanguageChange = (event) => {
        const newDiffGroups = [...diffGroups];
        newDiffGroups[index].accommodations.MLL.language = event.target.value;
        diffGroups[index].accommodations.MLL.language = event.target.value;
		setDiffGroups(newDiffGroups);
	};


    return (
        <>
        <div className="differentiationGroups-accommodations-option">
            <input 
                className="differentiationGroups-accommodations-option-checkbox"
                type="checkbox" 
                id="accommodations-MLL" 
                name="MLL" 
                onClick={() => handleToggleMLL(!expanded)}/>
            <label 
                className="differentiationGroups-accommodations-option-label"
                htmlFor="accommodations-MLL"
                >Multilingual Language Learner</label><br/>
        </div>
        {/* MLL Input */}
        { expanded && 
            <div
                style={{display: "flex", flexDirection: "column", gap: "5px", marginLeft: "15px"}}
            >
            <div className="differentiationGroups-accommodations-option">
                <input 
                    className="differentiationGroups-accommodations-option-checkbox"
                    type="radio" 
                    id="accommodations-full" 
                    name={`accommodations-MLL-group-${index + 1}`} 
                    value="Full Translation"
                    onClick={(e) => handleAccomodationChange(e, index)}/>
                <label 
                    className="differentiationGroups-accommodations-option-label"
                    htmlFor="accommodations-full"> 
                    Full Translations</label><br/>
            </div>
            <div className="differentiationGroups-accommodations-option">
                <input 
                    className="differentiationGroups-accommodations-option-checkbox"
                    type="radio" 
                    id="accommodations-wordBased" 
                    name={`accommodations-MLL-group-${index + 1}`} 
                    value="Word based Translation"
                    onClick={(e) => handleAccomodationChange(e, index)}/>
                <label 
                    className="differentiationGroups-accommodations-option-label"
                    htmlFor="accommodations-wordBased"> 
                    Translate Difficult Words</label><br/>
            </div>
            <div className="differentiationGroups-accommodations-option">
                <input 
                    className="differentiationGroups-accommodations-option-checkbox"
                    type="radio" 
                    id="accommodations-definition" 
                    name={`accommodations-MLL-group-${index + 1}`} 
                    value="English Definitions"
                    onClick={(e) => handleAccomodationChange(e, index)}/>
                <label 
                    className="differentiationGroups-accommodations-option-label"
                    htmlFor="accommodations-definitions"> 
                    Definitions in Easier English Words</label><br/>
            </div>
            {/* Language Input */}
            { diffGroups[index].accommodations.MLL.active && diffGroups[index].accommodations.MLL.type !== "English Definitions" && 
                <div className="differentiationGroups-group-section">
                    <label 
                        className="differentiationGroups-group-label"
                        htmlFor="createWorksheet-language"
                    >
                        Translation Language:
                        <p style={{color: "red", margin: "0"}}>*</p>
                    </label>
                    <input 
                        type="text" 
                        className={`differentiationGroups-group-textInput ${!isDiffGroupValid[index].language ? 'input-invalid' : ''}`}
                        id="createWorksheet-language" 
                        name="createWorksheet-language"
                        placeholder=" "
                        autoComplete="off"
                        value={diffGroups[index].accommodations.MLL.language}
                        onChange={(e) => handleLanguageChange(e, index)}/>
                </div>
            }
            </div>
        }
        </>
    );
}

ConfigureMLL.propTypes = {
    index: PropTypes.number,
    diffGroups: diffGroupsPropType,
    setDiffGroups: PropTypes.func,
    isDiffGroupValid: PropTypes.arrayOf(
        PropTypes.shape({
            grade_level: PropTypes.bool,
            num_questions: PropTypes.bool,
            language: PropTypes.bool
        })
    ),
};


export default ConfigureMLL;