import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { PDFViewer } from '@react-pdf/renderer';

// import { Document, Page } from 'react-pdf';
import MultipleChoiceWorksheet from '../../../WorksheetTemplates/MultipleChoiceWorksheet';
import FillInTheBlankWorksheet from '../../../WorksheetTemplates/FillInTheBlankWorksheet';
import ReadingComprehensionWorksheet from '../../../WorksheetTemplates/ReadingComprehensionWorksheet'
import WrittenResponseWorksheet from '../../../WorksheetTemplates/WrittenResponseWorksheet';
import QuizWorksheet from '../../../WorksheetTemplates/QuizWorksheet';

import LoadingBar from '../../../components/LoadingBar/LoadingBar';

import DeleteDropdown from './Dropdowns/DeleteDropdown';
import ExportDropdown from './Dropdowns/ExportDropdown';

import './PreviewWorksheet.css';
import { singleWorksheetDataPropType } from '../CreateWorksheetConstants';
import PopupConfirm from '../../../components/PopupConfirm/PopupConfirm';

const a4PageStyle = {
    width: '595px',  // A4 width at 72 DPI
    height: '100%',
    margin: '0 auto',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
};

const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    const debouncedFunction = useCallback((...args) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            callback(...args);
        }, delay);
    }, [callback, delay]);

    return debouncedFunction;
};

function PreviewWorksheet({ worksheetIndex, worksheetData, enableExport, full = false, deleteWs }) {
    const ref = useRef();

    const [resizeCount, setResizeCount] = useState(0);

    const [exportDropdownOpen, setExportDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const [exportLoading, setExportLoading] = useState(false);

    const [isExportPopupOpen, setIsExportPopupOpen] = useState(false);

    const [googleDocURL, setGoogleDocURL] = useState("");

    const config = {
        fontSize: parseInt(worksheetData.accommodations.IEP.accommodations.font_size, 10),
        lineSpacing: parseFloat(worksheetData.accommodations.IEP.accommodations.line_spacing),
        linesBetweenQuestions: 1,
        answerKey: true
    };

    // Update the PDF instance on resize
    const handleResize = useDebounce(() => {
        setResizeCount(resizeCount + 1);
    }, 500);

    // Resize react-pdf preview on window resize
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    // Dropdown minimize when user clicks off of it
    useEffect(() => {
        // Function to close dropdown if clicked outside
        const handleClickOutside = (event) => {
            if (dropdownRef.current
                && !dropdownRef.current.contains(event.target)
                && exportDropdownOpen
            ) {
                setExportDropdownOpen(false);
            }
        };

        // Attach the event listener to the document
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, exportDropdownOpen]);


    let worksheetDocument = null;

    switch (worksheetData.worksheet_type) {
        case "multiple choice":
            worksheetDocument = <MultipleChoiceWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;

        case "reading comprehension":
            worksheetDocument = <ReadingComprehensionWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;
        case "quiz worksheet":
            worksheetDocument = <QuizWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;
        case "written response":
            worksheetDocument = <WrittenResponseWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;
        default:
            worksheetDocument = <FillInTheBlankWorksheet
                isPdf={enableExport}
                worksheetData={worksheetData}
                worksheetIndex={worksheetIndex}
                config={config}
            />
            break;
    }

    if (!ref.current) {
        handleResize();
    }

    return (
        <div className="previewWorksheet-outer-container">{(!full) &&
            <div
                ref={ref}
                className="previewWorksheet-container"
                key={`preview-${worksheetIndex}`}
            >
                <div
                    className="previewWorksheet-header">
                    <h3 className="previewWorksheet-title">Preview</h3>
                    {enableExport &&
                        <div className="previewWorksheet-dropdown">
                            <ExportDropdown
                                worksheetDocument={worksheetDocument}
                                worksheetData={worksheetData}
                                worksheetIndex={worksheetIndex}
                                setPopupOpen={setIsExportPopupOpen}
                                setExportLoading={setExportLoading}
                                setGoogleDocURL={setGoogleDocURL}
                            />
                            <DeleteDropdown
                                worksheetIndex={worksheetIndex}
                                deleteWs={deleteWs}
                            />
                        </div>
                    }
                </div>
                {(!exportLoading && enableExport) &&
                    <PDFViewer
                        showToolbar={false}
                        style={{ width: ref.current ? ref.current.offsetWidth - 50 : "40vw", height: ref.current ? (ref.current.offsetWidth - 50) * 1.5 : "80vh" }}
                        key={`preview-PDFPreview-${worksheetIndex}`}
                    >
                        {worksheetDocument}
                    </PDFViewer>
                }
                {(!exportLoading && !enableExport) &&
                    (<div style={{overflowY: "scroll", border: '2px solid #ccc', width: "clamp(30%, 595px, 50px)",
                        borderRadius: '4px', ...a4PageStyle}}>{worksheetDocument}</div>)
                }
                {exportLoading &&
                    <LoadingBar duration="10" />
                }
            </div>
        }
        {
            (full) && (<div style={a4PageStyle}>{worksheetDocument}</div>)
        }
        {   isExportPopupOpen &&
            <PopupConfirm
                displayText="Click to open your Google Doc!" 
                confirmText="Open" exitText="Close" handleConfirm={() => window.open(googleDocURL, '_blank')} 
                handleExit={() => setIsExportPopupOpen(false)}
            /> 
        }
        </div>
    );
};


PreviewWorksheet.propTypes = {
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    enableExport: PropTypes.bool,
    full: PropTypes.bool,
    deleteWs: PropTypes.func
}

export default PreviewWorksheet;
