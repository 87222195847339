import React from "react";
import "./SiteHeader.css";

import profileIcon from "../../assets/SiteHeader/siteHeader-profile.svg";

function SiteHeader() {
	return (
		<div className="siteHeader-container">
			<img className="siteHeader-profile" src={profileIcon} alt="Profile" />
		</div>
	);
}

export default SiteHeader;
