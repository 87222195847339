import React, { useState } from 'react';
import { useUser, useFirestore } from 'reactfire';
import { collection, addDoc } from 'firebase/firestore';

import PopupConfirm from "../../../components/PopupConfirm/PopupConfirm";

import "./Feedback.css";
import "../Help.css";

function Feedback() {
    const [feedback, setFeedback] = useState("");
    const [showThankYouPopup, setShowThankYouPopup] = useState(false);

    const firestore = useFirestore();
    const { data: user } = useUser();

    async function submitFeedback() {
        try {
            const feedbackRef = collection(firestore, 'feedback');

            await addDoc(feedbackRef, {
                userFeedback: feedback,
                createdAt: new Date(),
                userID: user.uid,
            });

            console.log('Feedback sent successfully.');
        } catch (error) {
            console.error('Error submitting feedback: ', error.message);
        }
    }

    const handleInputChange = (e) => {
        setFeedback(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        

        if (feedback !== "") {
            submitFeedback();
            setFeedback("");
            setShowThankYouPopup(true);
            console.log("Feedback submitted:", feedback);
        }
    };

    return (
        <>
        <form className="feedback-form" onSubmit={handleSubmit}>
            <label 
                className="help-section-title"
                htmlFor="feedback"
            >
                Your Feedback:
            </label>
            <p className="help-section-subtitle">
                We value your input! Let us know how we can improve your experience.
            </p>
            <textarea
                id="feedback"
                className="feedback-textarea"
                value={feedback}
                onChange={handleInputChange}
                placeholder="Share your thoughts, suggestions, or any issues you encountered..."
                rows="4"
            />
            <button type="submit">Send Feedback</button>
        </form>

        {showThankYouPopup && 
            <PopupConfirm
                displayText="Thank you for the feedback!" 
                confirmText="Done" handleConfirm={() => setShowThankYouPopup(false)} 
            /> 
        }
        </>
    );
}

export default Feedback;
