import React, { useState, useEffect } from "react";

// import PropTypes from 'prop-types';
import "./Dashboard.css";
import Navbar from "../../components/NavBar/NavBar";
// import SiteHeader from "../../components/SiteHeader/SiteHeader";
import TemplatesDashboard from "./components/TemplatesSection/TemplatesDashboard";
import YourActivitiesDashboard from "./components/YourActivitiesSection/YourActivitesDashboard";
import CreateActivityPopup from "./components/CreateActivityPopup/CreateActivityPopup";
import Tutorial from "../../components/Tutorial/Tutorial";


function Dashboard() {

	const [tutorial, setTutorial] = useState(false); // TODO: Get from firestore if user hasnt done it yet
	const [tutorialType, setTutorialType] = useState("");
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [popupInputType, setPopupInputType] = useState("");
	const [popupTemplate, setPopupTemplate] = useState("");

	const openPopup = (startedFromInputType, selectedOption) => {
		setIsPopupVisible(true);
		setTutorial(true);
		if (startedFromInputType) {
			setPopupInputType(selectedOption);
			setPopupTemplate("");
		}
		else {
			setPopupInputType("");
			setPopupTemplate(selectedOption);
		}
	};

	const closePopup = () => {
		setIsPopupVisible(false);
		setPopupInputType("");
		setPopupTemplate("");
	}

	// Enable the tutorial after all children have loaded plus a small delay
	useEffect(() => {

		// if(isPopupVisible) return undefined;

		const timer = setTimeout(() => {
			setTutorial(true);
			if (isPopupVisible) {
				if (popupTemplate === "") {
					setTutorialType('choose_template');
				} else {
					setTutorialType('choose_method');
				}
			} else {
				setTutorialType('dashboard');
			}
		}, 500); // 500ms delay, adjust as needed

		return () => clearTimeout(timer);
	}, [isPopupVisible, popupTemplate]);

	return (
		<div className="base-container">
			<Navbar />
			<div className="base-content-container">
				{/* <SiteHeader/> */}
				<div className="dashboard-container">
					<Tutorial enable={tutorial} tutorial={tutorialType}/>
					<TemplatesDashboard openPopup={openPopup} />
					<YourActivitiesDashboard />
				</div>
			</div>
			{isPopupVisible && 
				<CreateActivityPopup 
					closePopup={closePopup} 
					inputType={popupInputType} 
					template={popupTemplate}
					setInputType={setPopupInputType}
					setTemplate={setPopupTemplate}
				/>
			}
		</div>
	);
}

export default Dashboard;
