import React from 'react';
import PropTypes from 'prop-types';
import { EmbeddedTextWrap, TextWrap, ViewWrap, NewLineWrap } from '../WorksheetWrappers';

import { questionPropType } from '../../pages/CreateWorksheet/CreateWorksheetConstants';

import { splitText } from '../LanguageAccessability';

function random(seed) {
    const x = Math.sin(seed + 1) * 10000;
    return x - Math.floor(x);
}


const shuffleArray = (array, qN) => {
    for (let i = array.length - 1; i > 0; i -= 1) {
        const j = Math.floor(random(new Date().getHours() + qN) * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};


// Create Document Component
function MultipleChoice({ question, questionIndex, config, isPdf }) {

    const combinedAnswers = (q, qN) => {
        const cA = [
            q.correct_answer,
            ...q.incorrect_answers.map((ans) => (ans)),
        ];
        return shuffleArray(cA, qN);
    };


    return (

        <ViewWrap isPdf={isPdf}
            styleName="answerOptionsContainer"
            styleProps={config}>
            {combinedAnswers(question, questionIndex).map((ans, index) => (
                <EmbeddedTextWrap
                    isPdf={isPdf}
                    key={`ansOption-${index + 1}`}
                    styleName=''
                    styleProps={config}>

                    <TextWrap
                        isPdf={isPdf}
                        key={`answerLetter-${questionIndex + 1}-${index + 1}`}
                        styleProps={config}
                        styleNames={['answerOption', 'inlineText', 'answerOptionIndex']}
                    >
                        {` ${String.fromCharCode(97 + index)}) `}
                    </TextWrap>
                    {splitText(ans).map((part, partIndex) => (

                        <TextWrap
                            isPdf={isPdf}
                            key={`answerSegment-${index + 1}-${partIndex + 1}`}
                            styleProps={config}
                            styleNames={['answerOption', 'inlineText', part.script]}
                        >
                            {part.text}
                        </TextWrap>

                    ))}
                </EmbeddedTextWrap>
            ))}
            <TextWrap
            isPdf={isPdf}
            styleProps={config}
            styleNames={['answerOption', 'inlineText']}>
                    {Array.from({ length: question.num_lines }).map((_, index) => (
                        <NewLineWrap key={`${question.id || 'question'}-newLineWrap-${index + 1}`} />
                    ))}
                </TextWrap>
        </ViewWrap>

    );
}

MultipleChoice.propTypes = {
    question: questionPropType,
    questionIndex: PropTypes.number.isRequired,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
    })
}

export default MultipleChoice;
