import React, { useState, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser, useFirestore } from 'reactfire';
import { collection, getDocs, doc, deleteDoc } from 'firebase/firestore';
import "./YourActivitiesDashboard.css";
import YourActivitiesDashboardHeader from "./components/YourActivitiesDashboardHeader";
import YourActivityCard from "./components/YourActivityCard";
import noActivitiesYet from '../../../../assets/Dashboard/YourActivities/noActivitiesImg.png';

// Format the date to "Month Day, Year"
const formattedDate = (date) => date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
});

// Function to fetch worksheet sets from Firestore
const fetchWorksheetSets = async ({ queryKey }) => {
    const [, firestore, user] = queryKey; // Destructure queryKey to get firestore and user
    if (!user) return [];  // Ensure user is available
    const worksheetSetsRef = collection(firestore, 'users', user.uid, 'worksheet_sets');
    const querySnapshot = await getDocs(worksheetSetsRef);

    return querySnapshot.docs.map(docu => (
        {
            id: docu.id,
            activity_name: docu.data().title,
            num_sheets: docu.data().size,
            thumbnail: docu.data().worksheetData[0],
            last_edited: formattedDate(docu.data().createdAt.toDate()),
            last_edited_real: docu.data().createdAt
        }
    )).sort((a, b) => b.last_edited_real.seconds - a.last_edited_real.seconds);
};

const deleteWorksheetSet = async (firestore, user, id) => {

    console.log("deleting");
    if (!user || !id) {
        console.error("User ID and worksheet ID are required to delete a worksheet set.");
        return;
    }

    const worksheetDocRef = doc(firestore, 'users', user.uid, 'worksheet_sets', id);

    try {
        await deleteDoc(worksheetDocRef);
        console.log(`Worksheet set with ID ${id} has been successfully deleted.`);
    } catch (error) {
        console.error("Error deleting worksheet set:", error);
    }
};

function YourActivitiesDashboard() {
    const { data: user } = useUser();
    const firestore = useFirestore();
    const queryClient = useQueryClient();


    const handleDelete = async (id) => {
        deleteWorksheetSet(firestore, user, id);

        // Optimistcally delete the set instead of waiting for the new one to return from firestore
        queryClient.setQueryData(['worksheetSets', firestore, user], (oldData) => {
            if (!oldData) return oldData;
            return oldData.filter(item => item.id !== id);
        });
    };
    const { data: activities = [], isLoading, error } = useQuery({
        queryKey: ['worksheetSets', firestore, user],
        queryFn: fetchWorksheetSets,
        enabled: !!user,
        refetchOnWindowFocus: false
    });

    const [searchInput, setSearchInput] = useState("");

    // Memoize filteredActivities directly from activities based on search input
    const filteredActivities = useMemo(() => {
        if (!searchInput) return activities; // Show all if no search input
        const lowerCaseKeyword = searchInput.toLowerCase();
        return activities.filter(activity =>
            activity.activity_name.toLowerCase().includes(lowerCaseKeyword)
        );
    }, [activities, searchInput]);

    return (
        <div className={`yourActivitiesDashboard-container ${activities.length === 0 ? 'empty' : ''}`}>
            <YourActivitiesDashboardHeader
                allActivities={activities}
                setSearchInput={setSearchInput}
            />

            {!isLoading && activities.length !== 0 && (
                <div className="yourActivitiesDashboard-grid">
                    {filteredActivities.map((activity) => (
                        <YourActivityCard key={activity.id} activityData={activity} deleteSet={handleDelete} />
                    ))}
                </div>
            )}

            {isLoading && <div>Loading...</div>}
            {error && <div>Error fetching activities...</div>}

            {!isLoading && activities.length === 0 && (
                <div className="yourActivitiesDashboard-noActivities-container">
                    <img
                        className="yourActivitiesDashboard-noActivities-img"
                        src={noActivitiesYet}
                        alt="No Activities Yet"
                    />
                    <p className="yourActivitiesDashboard-noActivities-text">
                        You don&apos;t have any worksheets yet! <br />
                        Import or choose a template to get started.
                    </p>
                </div>
            )}
        </div>
    );
}

export default YourActivitiesDashboard;
