import React, { useState } from 'react';
import PropTypes from 'prop-types';

import "../DifferentiationGroups.css";
import { diffGroupsPropType, readingLevels as readingLevelOptions } from "../../../CreateWorksheetConstants";


import SliderSelector from '../SharedComponents/SliderSelector';

function ConfigureReadingLevel ({ index, diffGroups, setDiffGroups }) {
    const [expanded, setExpanded] = useState(false);

    const handleToggleReadingLevel = (expand) => {
        setExpanded(expand);
        
        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index];

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }

        if (!expand) {
            targetGroup.accommodations.reading.reading_level = '';
        }
        
        targetGroup.accommodations.reading.active = expand;
        setDiffGroups(newDiffGroups);
    }

    const handleReadingLevelChange = (option) => {
        console.log("reading level changed to:", option.title, option.descriptor);

        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index]

        if (targetGroup === undefined) {
            console.log("Error: cannot find target group");
            return;
        }
        
        targetGroup.accommodations.reading.reading_level = `${option.title} ${option.descriptor}`;
        targetGroup.accommodations.reading.active = true;
		setDiffGroups(newDiffGroups);
    }

    return (
        <>
        <div className="differentiationGroups-accommodations-option">
            <input 
                className="differentiationGroups-accommodations-option-checkbox"
                type="checkbox" 
                id="accommodations-readingLevel" 
                name="accommodations-readingLevel" 
                value="Reading Level"
                onClick={() => handleToggleReadingLevel(!expanded)}/>
            <label 
                className="differentiationGroups-accommodations-option-label"
                htmlFor="accommodations-readingLevel"> 
                Reading Level</label><br/>
        </div>
        {/* Reading Level Input */}
        { expanded && 
            <div className="differentiationGroups-group-section">
                <SliderSelector
                    options={readingLevelOptions}
                    hasDescriptor
                    handleChange={(option) => handleReadingLevelChange(option)}    
                />
            </div>
        }
        </>

    );
}

ConfigureReadingLevel.propTypes = {
    index: PropTypes.number,
    diffGroups: diffGroupsPropType,
    setDiffGroups: PropTypes.func,
};

export default ConfigureReadingLevel;