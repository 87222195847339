import React from "react";
import "./TopStatistics.css"

function TopStatistics() {
    return (
        <div className="topStatisticsContainer">
            <div className="statContainer">
                <p>stat1</p>
            </div>
            <div className="statContainer">
                <p>stat2</p>
            </div>
            <div className="statContainer">
                <p>stat3</p>
            </div>
        </div>
    )
}

export default TopStatistics;