import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './SliderSelector.css';



function SliderSelector({ options, startingIndex, hasDescriptor, handleChange}) {
    const [selectedLevel, setSelectedLevel] = 
        useState(startingIndex && startingIndex < options.length ? options[startingIndex] : options[0]);

    const handleSliderChange = ({ target: { value } }) => {
        setSelectedLevel(options[value]);
        handleChange(options[value]);
    };

    return (
        <div className="sliderSelector-container">
                <input
                    type="range"
                    min="0"
                    max={options.length - 1}
                    value={options.indexOf(selectedLevel)}
                    className="sliderSelector-slider"
                    onChange={handleSliderChange}
                />
            <div className="sliderSelector-labels firstRow">
                {options.map((option, index) => (
                    <div 
                        className="sliderSelector-label-title"
                        key={`sliderSelector-option-${index + 1}`}
                    >
                        {option.title}
                    </div>
                ))}
            </div>
            {   hasDescriptor && 
                <div className="sliderSelector-labels">
                    {options.map((option, index) => (
                        <div 
                        className="sliderSelector-label-desc"
                        key={`sliderSelector-option-${index + 1}`}
                    >
                        {option.descriptor}
                    </div>
                    ))}
                </div>
            }
        </div>
    );
}

SliderSelector.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        descriptor: PropTypes.string,
	})),
    hasDescriptor: PropTypes.bool,
    handleChange: PropTypes.func,
    startingIndex: PropTypes.number,
};

export default SliderSelector;
