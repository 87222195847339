import React from 'react';
import PropTypes from 'prop-types';
import { ViewWrap } from './WorksheetWrappers';

import { singleWorksheetDataPropType } from '../pages/CreateWorksheet/CreateWorksheetConstants';
import QuestionText from './WorksheetComponents/QuestionText';
import MultipleChoice from './WorksheetComponents/MultipleChoice';
import WorksheetTemplate from './WorksheetTemplate';
import Glossary from './WorksheetComponents/Glossary';

const FONT_SIZE = 12;
const LINE_SPACING = 1.5;
const LINES_BETWEEN_QUESTIONS = 1;

// Create Document Component
function MultipleChoiceWorksheet({ worksheetData, worksheetIndex, isPdf = true, config = { fontSize: FONT_SIZE, lineSpacing: LINE_SPACING, linesBetweenQuestions: LINES_BETWEEN_QUESTIONS, answerKey: true }}) {

    return (
        <WorksheetTemplate isPdf = {isPdf} worksheetData={worksheetData} worksheetIndex={worksheetIndex} config={config}>

{worksheetData.questions.map((question, questionIndex) => (
                <ViewWrap
                    isPdf={isPdf}
                    styleName="questionContainer" styleProps={config}
                    elementKey={`question-${worksheetIndex}-${questionIndex + 1}`}
                    key={`question-${worksheetIndex}-${questionIndex + 1}`}
                >
                    {(() => {
                        switch (question.question_type) {
                            case 'glossary':
                                return <Glossary config={config} isPdf={isPdf} question={question} />
                            default:
                                return <ViewWrap
                                    isPdf={isPdf}
                                    styleName="" styleProps={config}
                                ><QuestionText isPdf={isPdf} question={question} questionIndex={questionIndex} config={config} />
                                    <MultipleChoice isPdf={isPdf} question={question} questionIndex={questionIndex} config={config} />
                                </ViewWrap>
                        }
                    })()}
                </ViewWrap>
            ))}
        </WorksheetTemplate>
    );
}

MultipleChoiceWorksheet.propTypes = {
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    isPdf: PropTypes.bool,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
        linesBetweenQuestions: PropTypes.number,
        answerKey: PropTypes.bool
    })
}

export default MultipleChoiceWorksheet;
