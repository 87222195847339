import React from "react";
import PropTypes from 'prop-types';
import "./achievementExpanded.css"

function AchievementExpanded({title, icon, inprogress, total, condition}) {
    return (
        <div className="expandedContainer">
            <img className="achieveIcon" src={icon} alt="icon"/>
            <div className="achieveInfo">
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div className="achieveTitle">{title}</div>
                    <div className="achieveLiteral">{inprogress} / {total}</div>
                </div>
                <div className="achieveProgressBar">
                    <div style={{background: 'var(--text-grey)', height: '100%', borderRadius: '12px'}}>
                        <div style={{
                                background: 'var(--secondary-color)',
                                width: `${(inprogress / total) * 100.0}%`,
                                height: '100%',
                                borderRadius: '12px',
                                boxShadow: '0px 1px var(--secondary-heavy-color)',
                                
                            }}/>
                    </div>
                </div>
                <div className="achieveCondition">{condition}</div>
            </div>
        </div>
    )
}

AchievementExpanded.propTypes = {
    title: PropTypes.string.isRequired,
    condition: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    inprogress: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired
}

export default AchievementExpanded;