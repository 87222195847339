import React, { useState } from 'react';
import { useSigninCheck, useUser, useFirestore } from 'reactfire';
import { doc, setDoc } from 'firebase/firestore';
import PropTypes from 'prop-types';

import './SignIn.css'; // Sharing styles with SignIn
import WizLabLogo from '../../assets/wizlab-logo-altColor.svg';
import Cauldron from '../../assets/SignIn/wizlab-cauldron.png';

function AccessGate ({ setIsAuthorized }) {
    const { data: user } = useUser(); // Get the current user
    const { status, data: signInCheckResult } = useSigninCheck(); // Check if user is signed in
    const [accessCode, setAccessCode] = useState('');
    const [error, setError] = useState('');

    const firestore = useFirestore();

    const handleAccessCodeSubmit = async (e) => {
        e.preventDefault();
        if (accessCode === process.env.REACT_APP_ACCESS_CODE) {
            // Store authorization status in Firestore
            await setDoc(doc(firestore, 'users', user.uid), { access_code_authorized: true });
            setIsAuthorized(true);
        } else {
            setError('Invalid access code. Please try again.');
        }
    };

    if (status === 'loading') return <p>Loading...</p>;

    if (!signInCheckResult.signedIn) {
        return <p>Please log in to access the site.</p>;
    }

    return (
        <div className='base-container'>
            <div className='signin-container'>
                <img 
                    className="signin-logo"
                    src={WizLabLogo}
                    alt="WizLab Logo"/>

                <div className="signin-header">
                    <h1 className="signin-title">Enter Access Code</h1>
                    <p className="signin-description">Enter the access code provided to you from us</p>
                </div>

                <form 
                    className="signin-emailSignIn-container"
                    onSubmit={handleAccessCodeSubmit}
                >
                    <input
                        className="signin-emailSignIn-input"
                        type="text"
                        value={accessCode}
                        onChange={(e) => setAccessCode(e.target.value)}
                        placeholder="Enter access code"
                        required
                    />
                    <button 
                        className="signin-oauth-option"
                        type="submit"
                    >
                        <p className="signin-oauth-text">Submit</p>
                    </button>
                </form>
                <p className="signin-description">Don&apos;t have an access code?
                <button 
                    type="button"
                    className="signin-goToSignup-link"
                    onClick={() => {
                        window.location.href = 'mailto:katia@wiz-lab.co';
                    }}>
                    Email Us
                </button>
                </p>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                
                <img 
                    className="signin-footer"
                    src={Cauldron} alt="Backdrop"/>
            </div>
            
        </div>
    );
};

AccessGate.propTypes = {
    setIsAuthorized: PropTypes.func,
}

export default AccessGate;
