import * as pdfjsLib from 'pdfjs-dist';


let workerSrc = false;

async function setupWorker() {
  if (!workerSrc) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
        // "pdfjs-dist/build/pdf.worker.min.js",
        "pdfjs-dist/build/pdf.worker.mjs",
        import.meta.url
      ).toString();
    workerSrc = true;
  }
}

async function PDFToBase64ImagesFunc(file) {
    if (!file) {
      throw new Error('No file provided');
    }
  
    try {
      await setupWorker();
  
      const arrayBuffer = await file.arrayBuffer();
      const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
      const { numPages } = pdf;
      const images = [];
  
      for (let pageNum = 1; pageNum <= numPages; pageNum += 1) {
        // eslint-disable-next-line no-await-in-loop
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;
  
        // eslint-disable-next-line no-await-in-loop
        await page.render({
          canvasContext: context,
          viewport,
        }).promise;
  
        const base64Image = canvas.toDataURL('image/png');
        images.push(base64Image);
      }
  
      return images;
    } catch (error) {
      console.error('Error processing PDF:', error);
      throw error; // Re-throw the error so the caller can handle it
    }
}

export default PDFToBase64ImagesFunc;
  