import React, { useState, useEffect } from "react";
import { useUser, useFirestore } from 'reactfire';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { Steps } from 'intro.js-react';

import PropTypes from 'prop-types';
import "./Tutorial.css";
import 'intro.js/introjs.css';

// Dashboard tutorial for onboarding users
const dashboard = [
	{
		element: '#dashboard-createWorksheetBanner',
		position: 'bottom',
		intro: '🌟 Welcome to WizLab! Ready to explore? Let’s take a quick tour of how WizLab can fit seamlessly into your teaching routine.',
	},
	{
		element: '#dashboard-createWorksheetBannerInfo',
		position: 'bottom',
		intro: '📚 We\'re here to help you quickly create personalized, differentiated worksheets for diverse learning needs!',
	},
	{
		element: '.templatesDashboard-createWorksheetSection-button',
		position: 'left',
		intro: '✏️ Click here to get started with creating your first worksheet!',
	},
	// {
	// 	element: '.templatesDashboard-row.templatesSection',
	// 	position: 'top',
	// 	tooltipClass: 'long-text',
	// 	highlightClass: 'library-card',
	// 	intro: 'Choose from any of our existing templates to generate your differentiated worksheet!',
	// },
]

const chooseTemplate = [
	{
		element: '#popupHeader',
		position: 'bottom',
		intro: '📄 Choose a template for your worksheet!',
	},
	{
		element: '.popup-options-container',
		position: 'top',
		intro: '✨ Browse our collection and pick a template that best fits your lesson. This will be the foundation for creating customized worksheets for your students!',
	},
]

const chooseMethod = [
	{
		element: '#popupHeader',
		position: 'bottom',
		intro: '⚙️ Select the type of content you want to differentiate! This is what you\'ll be importing.',
	},
	{
		element: '.popup-options-container',
		position: 'top',
		intro: '🎯 WizLab can help you generate customized materials from any of these sources.',
	},
]

const create = [
	{
		element: '.createWorksheet-header',
		position: 'bottom',
		tooltipClass: 'long-text',
		intro: '🛠️ Let’s set up your worksheet! Here, you’ll tell us how to personalize it for different learning needs.',
	},
	{
		element: '.createWorksheet-upload-container',
		position: 'bottom',
		tooltipClass: 'long-text',
		intro: '📤 Start by dragging and dropping your existing worksheet, lesson plan, or reading material here!',
	},
	{
		element: '#name-grade',
		position: 'bottom',
		tooltipClass: 'long-text',
		intro: '✏️ Next, give your worksheet a name and select the original grade level of the material you just imported.',
	},
	{
		element: '#additional-settings',
		position: 'bottom',
		tooltipClass: 'long-text',
		intro: '⚙️ You can also align your worksheet with specific standards or add any extra requirements here.',
	},
	{
		element: '.differentiationGroups-group',
		position: 'top',
		tooltipClass: 'long-text',
		intro: '🎯 Create your first differentiated worksheet version here, so it’s tailored to specific learners or skill levels.',
	},
	{
		element: '.differentiationGroups-group-section.difficulty',
		position: 'top',
		intro: '📏 Select the difficulty level to match your students’ grade or skill level. This ensures the worksheet is appropriately challenging!',
	},
	{
		element: '.differentiationGroups-group-section.numQuestions',
		position: 'top',
		intro: '🔢 Set the number of questions you want in this version. Choose between 1 and 20 questions for the ideal length!',
	},
	{
		element: '.differentiationGroups-group-section.accomodations',
		position: 'top',
		tooltipClass: 'long-text',
		intro: `📝 Check these boxes to adapt the worksheet for students with Individualized Education Programs (IEPs), enable translations for Multilingual 
				Language Learners, and adjust the Reading Level for this worksheet to ensure it’s suitable for a specific group of your students.`,
	},
	{
		element: '.differentiationGroups-addGroup-button',
		position: 'top',
		intro: '➕ Click here to add more versions, each customized for different student needs!',
	},
	{
		element: '.createWorksheet-submit-button',
		position: 'top',
		intro: "🚀 When you're ready, click here to generate your personalized worksheets!",
	},
];

const edit = [
	{
		element: '.undefined',
		position: 'top',
		tooltipClass: 'long-text',
		intro: '✨ Time to polish your worksheet! Here, you can make edits and customize it to suit your students’ needs.',
	},
	{
		element: '.previewEditWorksheet-header-leftItems',
		position: 'bottom',
		tooltipClass: 'long-text',
		intro: '📋 Here’s the title, grade level, and any accommodations or settings applied to this worksheet.',
	},
	{
		element: '.editWorksheet-container',
		position: 'top',
		tooltipClass: 'long-text',
		intro: '✏️ Edit and arrange questions here. Modify as needed to create the perfect worksheet!',
	},
	{
		element: '.editWorksheet-listItem-container',
		position: 'right',
		tooltipClass: 'long-text',
		intro: '📝 Edit each question individually. You can change the question text, correct answer, and any incorrect answers.',
	},
	{
		element: '.editWorksheet-listItem-handle',
		position: 'top',
		tooltipClass: 'long-text',
		intro: '🔀 Click and drag to reorder questions as you like.',
	},
	{
		element: '.editWorksheet-listItem-spaceAdder',
		position: 'top',
		intro: '➕➖ Add or remove spacing between questions for working out.',
	},
	{
		element: '#editWorksheet-addAndRemove',
		position: 'left',
		intro: '➕ Use these buttons to add new questions or remove existing ones.',
	},
	{
		element: '.previewWorksheet-container',
		position: 'top',
		tooltipClass: 'long-text',
		intro: '👀 See a live preview of your worksheet with all recent changes here.',
	},
	{
		element: '.previewEditWorksheet-header-rightItems',
		position: 'left',
		intro: '➡️ Navigate through different worksheet versions here.',
	},
	{
		element: '.previewEditWorksheet-banner-header-rightItems',
		position: 'bottom',
		intro: '🚀 All done? Click here to export your worksheet! Or regenerate if you’d like to try new questions. You can always return and edit later.',
	},
];


const tutorials = [dashboard, chooseTemplate, chooseMethod, create, edit]
const completed = [{ dashboard_tutorial: true }, {choose_template_tutorial: true}, {choose_method_tutorial: true}, { create_worksheet_tutorial: true }, { edit_worksheet_tutorial: true }]

function Tutorial({enable, tutorial}) {

	const { data: user } = useUser();
	const firestore = useFirestore();
	const [start, setStart] = useState(false);

	let t = -1;
    switch(tutorial) {
		case "dashboard":
			t = 0;
			break;
		case "choose_template":
			t = 1;
			break;
		case "choose_method":
			t = 2;
			break;
		case "create_worksheet":
			t = 3;
			break;
		case "edit_worksheet":
			t = 4;
			break;
		default:
			console.log("Invalid tutorial name");
    }

	const endTutorial = async () => {
		if (user && tutorial && start) {
			try {
				const userDocRef = doc(firestore, 'users', user.uid);
				await setDoc(userDocRef, completed[t], { merge: true });
				setStart(false);
				console.log("Tutorial field set to true.");
			} catch (error) {
				console.error("Error setting tutorial field:", error);
			}
		}
	};

	useEffect(() => {

		if(!enable) return;

		const checkTutorial = async () => {
			if (user) {
				const userDocRef = doc(firestore, 'users', user.uid);
				const userDocSnap = await getDoc(userDocRef);

				if (!userDocSnap.exists() || !(userDocSnap.data()[`${tutorial}_tutorial`] === true)) {
					setStart(true);
					console.log("enabling tutorial");
				}
			}
			return null;
		};

		checkTutorial();
	}, [user, firestore, enable, tutorial]);

	if ( t === -1 ) return null;

	return (
			<Steps
				enabled={start}
				steps={tutorials[t]}
				initialStep={0}
				onExit={() => endTutorial()}
				options={{
					disableInteraction: t !== 3,
					exitOnOverlayClick: false,
					autoPosition: false,
					hidePrev: true,
					buttonClass: "basic-button",
					tooltipClass: "basic-pop-up",
					showBullets: false,
					scrollToElement: t === 3,
				}}
			/>
	);
}

Tutorial.propTypes = {
    enable: PropTypes.bool,
    tutorial: PropTypes.string,
}

export default Tutorial;
