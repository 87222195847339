import React from "react";
import PropTypes from 'prop-types';
import "./YourActivitiesDashboardHeader.css";
import searchIcon from "../../../../../assets/shared/searchIcon.svg";

function YourActivitiesDashboardHeader({ setSearchInput }) {
    const handleSearchChange = (e) => {
        // Update the search input in the parent component
        setSearchInput(e.target.value);
    };

    return (
        <div className="yourActivitiesDashboardHeader-container">
            <h1 className="yourActivitiesDashboardHeader-heading">Your Worksheets</h1>
            <div className="yourActivitiesDashboardHeader-rightItems">
                <div className="yourActivitiesDashboardHeader-searchBar">
                    <input
                        id="yourActivitiesDashboardHeader-searchInput"
                        className="yourActivitiesDashboardHeader-searchBar-input"
                        placeholder="Search Worksheets..."
                        autoComplete="off"
                        onChange={handleSearchChange}
                    />
                    <img className="yourActivitiesDashboardHeader-searchBar-icon" src={searchIcon} alt="Search" />
                </div>
                {/* Uncomment if you want to add the filter button */}
                {/* <button className="yourActivitiesDashboardHeader-filterButton" type="button">
                    <img className="yourActivitiesDashboardHeader-filter" src={filterIcon} alt="filter" />
                </button> */}
            </div>
        </div>
    );
}

YourActivitiesDashboardHeader.propTypes = {
    setSearchInput: PropTypes.func.isRequired,
};

export default YourActivitiesDashboardHeader;
