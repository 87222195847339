import React, { createContext, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// Create the TestingContext
const TestingEnvContext = createContext();

// Create a provider component
export function TestingEnvProvider({ children }) {
  const [isTesting] = useState(false); // Initial state: false

  const value = useMemo(() => ({ isTesting }), [isTesting]);

  return (
    <TestingEnvContext.Provider value={value}>
      {children}
    </TestingEnvContext.Provider>
  );
};

TestingEnvProvider.propTypes = {
    children: PropTypes.node.isRequired,
  };

// Custom hook to use the TestingContext
export const useTestingContext = () => useContext(TestingEnvContext);
