import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './Dropdown.css';

function Dropdown({ buttonText, buttonIcon, className, id, isUp, children }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Dropdown minimize when user clicks off of it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                dropdownOpen
            ) {
                setDropdownOpen(false);
            } else if (dropdownOpen) {
                // Set a short timer to close the dropdown
                setTimeout(() => {
                    setDropdownOpen(false);
                }, 200); // Adjust the delay as needed
            }
        };

        // Attach the event listener to detect clicks outside the dropdown
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);

    return (
        <div
            className="dropdown"
        >
            <button
                type="button"
                onClick={() => { setDropdownOpen(prev => !prev); }}
                className={ className }
                id={id}
            >
                { buttonIcon ?? buttonText }
            </button>

            {dropdownOpen && (
                <div
                    className="dropdown-content"
                    id={`${isUp ? "up" : "down"}`}
                    ref={dropdownRef}
                >
                    {children}
                </div>
            )}
        </div>
    );
};

Dropdown.propTypes = {
    buttonText: PropTypes.string.isRequired,
    buttonIcon: PropTypes.element,
    className: PropTypes.string,
    id: PropTypes.string,
    isUp: PropTypes.bool,
    children: PropTypes.element
}

export default Dropdown;