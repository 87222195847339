import React from "react";
import { createRoot } from 'react-dom/client';
import { FirebaseAppProvider } from 'reactfire';

import "./index.css";
import FirebaseAppWrapper from "./FirebaseAppWrapper";
import reportWebVitals from "./reportWebVitals";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyDB1WvPj6RPEzkXze0IowT8RkRqLpBY-dg",
	authDomain: "wizlab-8c906.firebaseapp.com",
	projectId: "wizlab-8c906",
	storageBucket: "wizlab-8c906.appspot.com",
	messagingSenderId: "885578082644",
	appId: "1:885578082644:web:fc63f556366695c69880c1",
	measurementId: "G-D99QSG5YSK"
};

function Root() {
	return (
		<FirebaseAppProvider firebaseConfig={firebaseConfig}>
			<React.StrictMode>
				<FirebaseAppWrapper />
			</React.StrictMode>
		</FirebaseAppProvider>
	);
}
createRoot(document.getElementById('root')).render(<Root />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
