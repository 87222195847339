import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DifferentiationGroups.css";

import deleteIcon from "../../../../assets/CreateWorksheet/deleteIcon.svg";
import addIcon from "../../../../assets/CreateWorksheet/addIcon.svg";
import worksheetsIcon from "../../../../assets/CreateWorksheet/stackIcon.svg";
import editIcon from '../../../../assets/CreateWorksheet/pencilIcon2.svg';

import { diffGroupsPropType, gradeLevelsPropType } from "../../CreateWorksheetConstants";
import { emptyDifferentiaionGroup } from "../../CreateWorksheetObjects";
import ConfigureIEP from "./DifferentiationGroupComponents/ConfigureIEP";
import ConfigureMLL from "./DifferentiationGroupComponents/ConfigureMLL";
import ConfigureReadingLevel from "./DifferentiationGroupComponents/ConfigureReadingLevel";

function DifferentiationGroups({ diffGroups, setDiffGroups, gradeLevels, isDiffGroupValid, setIsDiffGroupValid }) {

    const [isEditing, setIsEditing] = useState(Array(diffGroups.length).fill(false)); // Initialize an array of booleans

    const handleVersionTitleChange = (event, index) => {
        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index];
        targetGroup.version_title = event.target.value;
		setDiffGroups(newDiffGroups);
	};

    const handleGradeLevelChange = (event, index) => {
        const newDiffGroups = [...diffGroups];
        const targetGroup = newDiffGroups[index];
        targetGroup.grade_level = event.target.value;
		setDiffGroups(newDiffGroups);
	};

    const handleNumQuestionsChange = (event, index) => {
        // if not a number
        if (!Number(event.target.value) && event.target.value !== "" ) {
            return;
        }
        // if out of range
        if (event.target.value !== "" && 
            (event.target.value < 1 || event.target.value > 20)) {
            return;
        }

        const newDiffGroups = [...diffGroups];
        newDiffGroups[index].num_questions = event.target.value;
        diffGroups[index].num_questions = event.target.value;
		setDiffGroups(newDiffGroups);
	};

    const handleRemoveGroup = (index) => {
        const newDiffGroups = [...diffGroups];
        newDiffGroups.splice(index, 1);
        setDiffGroups(newDiffGroups);
    
        const newDiffGroupValid = [...isDiffGroupValid];
        newDiffGroupValid.splice(index, 1);
        setIsDiffGroupValid(newDiffGroupValid);
    
        const newIsEditing = [...isEditing];
        newIsEditing.splice(index, 1); // Remove the corresponding isEditing state
        setIsEditing(newIsEditing);
    };
    
    const handleAddGroup = () => {
        const newDiffGroups = [...diffGroups];
        newDiffGroups.push(structuredClone(emptyDifferentiaionGroup)); // Add new group
        setDiffGroups(newDiffGroups);
    
        const newDiffGroupValid = [...isDiffGroupValid];
        newDiffGroupValid.push({
            grade_level: true,
            num_questions: true,
            language: true,
        });
        setIsDiffGroupValid(newDiffGroupValid);
    
        const newIsEditing = [...isEditing];
        newIsEditing.push(false); // Add a corresponding isEditing state for the new group
        setIsEditing(newIsEditing);
    
        console.log(diffGroups);
    };

    const toggleEditMode = (index, editing) => {
        const updatedEditing = [...isEditing];
        updatedEditing[index] = editing;
        setIsEditing(updatedEditing);
    };
      

    return (
        <div className="differentiationGroups-container">
            <div>
                <div
                    style={{display: "flex", gap: "5px"}}>
                    <img
                        className="differentiationGroups-title-icon"
                        src={worksheetsIcon} alt="Create you worksheet"/>
                    <h3 className="differentiationGroups-header">Worksheet Versions:</h3>
                    <p style={{color: "red", margin: "0"}}>*</p>
                </div>
                <p className="differentiationGroups-description">Create your differentiated worksheet versions here!</p>
            </div>
            
            <div className="differentiationGroups-groups">
            {diffGroups.map((group, index) => (
                <div className="differentiationGroups-group" key={`Group ${index + 1}`} >

                    {/* Header */}
                    <div style={{width: "100%"}}>
                        <div className="differentiationGroups-group-header">
                            {isEditing[index] ? (
                                <input
                                    type="text"
                                    className="differentiationGroups-group-textInput"
                                    placeholder={`Version ${index + 1}`}
                                    value={isEditing[index] ? diffGroups[index].version_title : (diffGroups[index].version_title || `Version ${index + 1}`)}
                                    onChange={(e) => handleVersionTitleChange(e, index)}
                                    onBlur={() => toggleEditMode(index, false)} // Exit edit mode on blur
                                />
                            ) : (
                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <h4
                                        className="differentiationGroups-group-title"
                                    >
                                        {diffGroups[index].version_title || `Version ${index + 1}`}
                                    </h4>
                                    <button
                                        type="button"
                                        className="differentiationGroups-group-editIcon"
                                        onClick={() => toggleEditMode(index, true)}
                                        style={{ width: "20px", height: "20px", padding: "0"}}
                                    >
                                        <img 
                                            src={editIcon} 
                                            alt="Edit version title"
                                            style={{ width: "16px", height: "18px", cursor: "pointer"}}
                                        />
                                    </button>
                                </div>
                            )}
                            <button 
                                className="differentiationGroups-group-removeIcon"
                                type="button"
                                onClick={() => handleRemoveGroup(index)}>
                                <img 
                                    className="differentiationGroups-group-removeIcon"
                                    src={deleteIcon} alt="Remove group"/>
                            </button>
                        </div>
                        <div className="differentiationGroups-group-lineSeperator"/>
                    </div>
                
                    {/* Row 1 */}        
                    <div
                        style={{display: "flex", width: "100%", gap: "15px"}}
                    >
                        {/* Grade Level Selection */}
                        <div className="differentiationGroups-group-section difficulty">
                            <label 
                                className="differentiationGroups-group-label"
                                htmlFor={`gradeLevelSelector-${group.id}`}
                                >Difficulty Level:
                                <p style={{color: "red", margin: "0"}}>*</p>
                            </label>
                            <select
                                className={`differentiationGroups-group-selector ${!isDiffGroupValid[index].grade_level ? 'input-invalid' : ''}`}
                                id={`gradeLevelSelector-${index + 1}`}
                                value={group.grade_level}
                                onChange={(e) => handleGradeLevelChange(e, index)}
                            >
                                <option value="">Select a grade level</option>
                                {gradeLevels.map((gradeLevel) => (
                                    <option key={gradeLevel.id} value={gradeLevel.name}>
                                        {gradeLevel.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {/* Num Questions Input */}
                        <div className="differentiationGroups-group-section numQuestions">
                            <label 
                                className="differentiationGroups-group-label"
                                htmlFor="createWorksheet-numQuestions"
                                >Number of Questions (1-20):
                                <p style={{color: "red", margin: "0"}}>*</p>
                            </label>
                            <input 
                                type="text" 
                                className={`differentiationGroups-group-textInput ${!isDiffGroupValid[index].num_questions ? 'input-invalid' : ''}`}
                                id="createWorksheet-numQuestions" 
                                name="createWorksheet-numQuestions"
                                placeholder=" "
                                autoComplete="off"
                                value={diffGroups[index].num_questions}
                                onChange={(e) => handleNumQuestionsChange(e, index)}/>
                        </div>
                    </div>

                    {/* Accommodations Selection */}
                    <div className="differentiationGroups-group-section accomodations">
                        <div className="differentiationGroups-group-label"
                        >
                            Accommodations:
                            <p style={{color: "var(--mid-light-grey)", alignSelf: "flex-end", fontSize: "14px", margin: "0", paddingBottom: "2px"}}>
                                (optional)
                            </p>
                        </div>
                        <ConfigureIEP
                            index={index}
                            diffGroups={diffGroups}
                            setDiffGroups={setDiffGroups}
                        />

                        <ConfigureMLL
                            index={index}
                            diffGroups={diffGroups}
                            setDiffGroups={setDiffGroups}
                            isDiffGroupValid={isDiffGroupValid}
                        />

                        <ConfigureReadingLevel
                            index={index}
                            diffGroups={diffGroups}
                            setDiffGroups={setDiffGroups}
                        />
                    </div>
                </div>
            ))}
            </div>
            { diffGroups.length <= 6 && 
                <button
                    className="differentiationGroups-addGroup-button"
                    type="button"
                    onClick={handleAddGroup}>
                    <img 
                        className="differentiationGroups-addGroup-icon"
                        src={addIcon} alt="Add Group"/>
                    Add Group
                </button>
            }
        </div>
  );
}

DifferentiationGroups.propTypes = {
    diffGroups: diffGroupsPropType,
    setDiffGroups: PropTypes.func,
    gradeLevels: gradeLevelsPropType,
    isDiffGroupValid: PropTypes.arrayOf(
        PropTypes.shape({
            grade_level: PropTypes.bool,
            num_questions: PropTypes.bool,
            language: PropTypes.bool
        })
    ),
    setIsDiffGroupValid: PropTypes.func,
};

export default DifferentiationGroups;
