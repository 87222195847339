import React from "react";
import "./ProfileInfo.css";

import profileIcon from "../../../assets/SiteHeader/siteHeader-profile.svg";

function ProfileInfo() {
    return (
        <div className="profile">
            <img className="profilePic" src={profileIcon} width={250} height={250} alt="profilepic"/>
            <div className="profileInfo">
                <div className="name">Teacher Name</div>
                <div>Joined January 1970</div>
            </div>
        </div>
    )
}

export default ProfileInfo;