import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { doc, getDoc } from 'firebase/firestore'
import { useSigninCheck, useUser, useFirestore } from 'reactfire';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import "./App.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import MyLab from "./pages/MyLab/MyLab";
import Help from "./pages/Help/Help";
import TeacherProfile from "./pages/TeacherProfile/TeacherProfile";
import CreateWorksheet from "./pages/CreateWorksheet/CreateWorksheet";
import UnderConstruction from "./pages/UnderConstruction/UnderConstruction";
import OAuthCallback from "./pages/AuthPages/OAuthCallback";
import SignIn from "./pages/AuthPages/SignIn";
import AccessGate from "./pages/AuthPages/AccessGate";

import LoadingBar from "./components/LoadingBar/LoadingBar";
import EditWorksheet from "./pages/EditWorksheet/EditWorksheet";
import { NavbarProvider } from "./components/NavBar/NavBar";
import { TestingEnvProvider } from './TestingEnvContext';

import './fonts/AvenirNextLTPro-Regular.otf';

// Create a client
const queryClient = new QueryClient();

function App() {

	const { status: authStatus, data: signInCheckResult } = useSigninCheck();
    const { data: user } = useUser();
    const firestore = useFirestore();

    const [isAuthorized, setIsAuthorized] = useState(false);
    const [authCodeLoadStatus, setAuthCodeLoadStatus] = useState("loading");

    useEffect(() => {
        // Only check authorization if the user is logged in
        const checkAuthorization = async () => {
            if (user) {
                try {
                    const userDocRef = doc(firestore, "users", user.uid);
                    const userDoc = await getDoc(userDocRef);

                    if (userDoc.exists() && userDoc.data().access_code_authorized) {
                        setIsAuthorized(true);
                    }
                    setAuthCodeLoadStatus("done");
                } catch (error) {
                    console.error("Error fetching user document:", error);
                    setAuthCodeLoadStatus("done");
                }
            } else {
                setAuthCodeLoadStatus("not-logged-in"); // Skip authorization check if not logged in
            }
        };

        if (user) {
            checkAuthorization();
        } else {
            setAuthCodeLoadStatus("not-logged-in");
        }
    }, [user, firestore]);

    // If auth status is loading or if authorization status is being checked, show loading
    if (authStatus === "loading" || authCodeLoadStatus === "loading") {
        return <LoadingBar duration="2" />;
    }

    // If user is not signed in, show the sign-in page
    if (!signInCheckResult.signedIn && authStatus !== "loading") {
        return <SignIn />;
    }

    // If user is signed in but not authorized, show access gate
    if (!isAuthorized && authCodeLoadStatus === "done") {
        return <AccessGate setIsAuthorized={setIsAuthorized} />;
    }
	
	// Final render if user is signed in and authorized
	console.log("User is signed in and authorized. Rendering main app content.");

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
					<TestingEnvProvider>
					<NavbarProvider>
						<Routes>
							<Route path="" element={<Dashboard />} />
							<Route path="home" element={<Dashboard />} />
							<Route path="my-lab" element={<MyLab />} />
							<Route path="profile" element={<TeacherProfile />} />
							<Route path="create-worksheet" element={<CreateWorksheet />} />
							<Route path="view-worksheet" element={<EditWorksheet />} />
							<Route path="explore" element={<UnderConstruction />} />
							<Route path="statistics" element={<UnderConstruction />} />
							<Route path="community" element={<UnderConstruction />} />
							<Route path="help" element={<Help />} />
							<Route path="/oauth-callback" element={<OAuthCallback />} />

							{/* <Route path="community" element={<ExternalLink url="https://wizlab.substack.com/"/>} /> */}
							{/* <Route path="test" element={<EditWorksheetTest />} /> */}
						</Routes>
					</NavbarProvider>
					</TestingEnvProvider>
			</Router>
		</QueryClientProvider>
	);
}

export default App;
