import React, { useState } from "react";
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { useAuth } from 'reactfire';

import "./SignIn.css";
import WizLabLogo from '../../assets/wizlab-logo-altColor.svg';
import GoogleLogo from '../../assets/SignIn/Google_logo.svg';
import Cauldron from '../../assets/SignIn/wizlab-cauldron.png';


function SignIn() {
    const [ isSignIn, setIsSignIn ] = useState(true);
    const [ error, setError ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ firstName, setFirstName ] = useState("");
    const [ lastName, setLastname ] = useState("");

    const auth = useAuth();

    const parseError = (errorMsg) => {
        console.log(errorMsg);
        if (errorMsg.includes("auth/invalid-credential")) {
            setError("Invalid email.");
        }
        else if (errorMsg.includes("auth/email-already-exists")) {
            setError("Email already exists.");
        }
        else if (errorMsg.includes("auth/invalid-password")) {
            setError("Password must be at least 6 characters.");
        }
        else if (errorMsg.includes("auth/invalid-login-credentials")){
            setError("Invalid Login Credentials.");
        }
        else {
            setError(errorMsg);
        }
    }

    const handleGooglePopupSignIn = async (authObj) => {
        setError("");
        const provider = new GoogleAuthProvider();
        await signInWithPopup(authObj, provider);
    };

    const handleEmailSignIn = async () => {
        setError("")
        signInWithEmailAndPassword(auth, email, password)
        // .then(() => {
        //     forceUpdate();
        // }) 
        .catch((err) => {
            const errorCode = err.code;
            parseError(errorCode)
        });
    }

    const handleEmailCreateAccount = async () => {
        setError("");
        createUserWithEmailAndPassword(auth, email, password)
        // .then(() => {
        //     // TODO: create teacher profile with name
        //     forceUpdate();
        // })
        .catch((err) => {
            const errorCode = err.code;
            parseError(errorCode);
        });
    }

	return (
		<div className="base-container">
            <div className="signin-container">
                <img 
                    className="signin-logo"
                    src={WizLabLogo}
                    alt="WizLab Logo"/>
                <div className="signin-header">
                    <h1 className="signin-title">{isSignIn ? "Sign In" : "Sign Up"}</h1>
                    <p className="signin-description">{isSignIn ? "Log In to your WizLab Account" : "Create your WizLab Account"}</p>
                </div>

                <button 
                    type="button"
                    className="signin-oauth-option"
                    onClick={() => handleGooglePopupSignIn(auth)}>
                    <img 
                        className="signin-oauth-logo"
                        src={GoogleLogo}
                        alt="Google"/>
                    <p className="signin-oauth-text">{isSignIn ? "Sign In with Google" : "Sign Up with Google"}</p>
                </button> 
                <p className="signin-description"><b>or</b></p>
                <div className="signin-emailSignIn-container">
                    {  !isSignIn && 
                        <div className="signin-name-container">
                        <input
                            type="text"
                            className="signin-emailSignIn-input half"
                            value={firstName}
                            placeholder="First Name"
                            autoComplete="off"
                            onChange={(e) => setFirstName(e.target.value)}
                            />
                        <input
                            type="text"
                            className="signin-emailSignIn-input half"
                            value={lastName}
                            placeholder="Last Name"
                            autoComplete="off"
                            onChange={(e) => setLastname(e.target.value)}
                            />
                        </div>

                    }
                    <input
                        type="text"
                        className="signin-emailSignIn-input"
                        value={email}
                        placeholder="Enter your email"
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                        />
                    <input
                        type="password"
                        className="signin-emailSignIn-input"
                        value={password}
                        placeholder="Enter your password"
                        autoComplete="off"
                        onChange={(e) => setPassword(e.target.value)}
                        />
                    { isSignIn ?
                        <button 
                            type="button"
                            className="signin-oauth-option"
                            onClick={handleEmailSignIn}>
                            <p className="signin-oauth-text">Log In</p>
                        </button> 
                        :
                        <button 
                            type="button"
                            className="signin-oauth-option"
                            onClick={handleEmailCreateAccount}>
                            <p className="signin-oauth-text">Sign Up</p>
                        </button> 
                    }
                </div>
                {   error && 
                    <p className="signin-errorMsg">{error} Try Again.</p>
                }
                { isSignIn && 
                    <p className="signin-description">Not a member yet?
                        <button 
                            type="button"
                            className="signin-goToSignup-link"
                            onClick={() => {setIsSignIn(false); setError("")}}>
                                Sign Up</button>
                    </p>
                }
                { !isSignIn && 
                    <p className="signin-description">Already a member yet?
                        <button 
                            type="button"
                            className="signin-goToSignup-link"
                            onClick={() => {setIsSignIn(true); setError("")}}>
                                Log In</button>
                    </p>
                }
                <img 
                    className="signin-footer"
                    src={Cauldron} alt="Backdrop"/>
                
            </div>
			
		</div>
	);
}

export default SignIn;
