
// Cleans up inputted worksheets data to ensure correct ordering of questions, available
// types, and question types
const cleanReturnedWorksheet = (worksheets) => worksheets.map(worksheet => {
    // Append relevant worksheet data that the AI is not inputting
    switch (worksheet.worksheet_type) {
        case "fill in the blank":
            worksheet.available_types = ["fill in the blank"];
            break;
        case "multiple choice":
            worksheet.available_types = ["multiple choice"];
            break;
        case "written response":
            worksheet.available_types = ["written response"];
            break;
        default:
            worksheet.available_types = ["multiple choice", "fill in the blank", "written response"];
    }

    // Process questions and handle glossary question separately
    let glossaryQuestion = null;
    const processedQuestions = worksheet.questions.filter(question => {
        if (question.question_type === "glossary") {
            glossaryQuestion = question;
            return false; // Exclude from processedQuestions
        }

        question.num_lines = 1;

        if (worksheet.worksheet_type === "multiple choice" || worksheet.worksheet_type === "fill in the blank") {
            question.question_type = worksheet.worksheet_type;
        } else if (question.question_type === "short answer") {
            question.question_type = "written answer";
        } else if (question.question_type === "medium answer") {
            question.num_lines = 5;
            question.question_type = "written answer";
        } else if (question.question_type === "long answer") {
            question.num_lines = 5;
            question.question_type = "written answer";
        }

        return true; // Include in processedQuestions
    });

    // Add the glossary question at the end if it exists
    if (glossaryQuestion) {
        processedQuestions.push(glossaryQuestion);
    }

    worksheet.questions = processedQuestions;
    return worksheet;
});

export default cleanReturnedWorksheet;