import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { pdfjs } from 'react-pdf';

import "./PreviewEditWorksheet.css";
import "../CreateWorksheet.css";
import FinishIcon from '../../../assets/CreateWorksheet/finishIcon.svg';
import PreviewWorksheet from "../SharedComponents/PreviewWorksheet";
import EditWorksheet from "./components/EditWorksheetV2";
import { allWorksheetDataPropType } from "../CreateWorksheetConstants";

import Tutorial from "../../../components/Tutorial/Tutorial";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();
  

function PreviewEditWorksheet({ worksheetDataInput, finishAndSave, setStatus }) {
    const [worksheetData, setWorksheetData] = useState(worksheetDataInput);
    const [currentWorksheetIndex, setCurrentWorksheetIndex] = useState(0);

    const [tutorial, setTutorial] = useState(false);

    function handleNextWorksheet() {
        setCurrentWorksheetIndex(prevIndex => prevIndex + 1);
    }

    function handlePrevWorksheet() {
        setCurrentWorksheetIndex(prevIndex => prevIndex - 1);
    }

    function prettyPrintActiveAccommodations(accommodations) {
        let result = '';
    
        if (accommodations.reading.active) {
            result += `Reading Level: ${accommodations.reading.reading_level}, `;
        }
        if (accommodations.MLL.active) {
            result += `Multilingual Learner: ${accommodations.MLL.type}`;
            if (accommodations.MLL.type === "Full Translation" || accommodations.MLL.type === "Word based Translation") {
                result += ` (${accommodations.MLL.language})`;
            }
            result += ', ';
        }
        if (accommodations.IEP.active) {
            const accommodationEntries = Object.entries(accommodations.IEP.accommodations)
                .map(([key, value]) => `${key.replace('_', ' ')}: ${value}`)
                .join(', ');
            
            result += `IEP: ${accommodationEntries}, `;
        }
        if (accommodations.DOK.active) {
            result += `DOK Level: ${accommodations.DOK.level}, `;
        }
    
        // If no accommodations are active
        if (result === '') {
            return "";
        }
        result = result.substring(0, result.length - 2);
    
        return result;
    }

    useEffect(() => {

		const timer = setTimeout(() => {
			setTutorial(true);
		}, 500); // 500ms delay, adjust as needed

		return () => clearTimeout(timer);
	}, []);

    return (
        <>
        <Tutorial enable={tutorial} tutorial="edit_worksheet"/>
        <div className="createWorksheet-container">
            <div className="createWorksheet-header-container">
                <div className="previewEditWorksheet-banner-header-leftItems">
                    <h1 className="createWorksheet-header">
                        Preview and Edit your Worksheet</h1>
                    <p className="createWorksheet-subHeader">
                        Use the side buttons to navigate between each worksheet then make edits or finish!</p>
                </div>
                <div className="previewEditWorksheet-banner-header-rightItems">
                    {setStatus && <button
                        type="button"
                        className="previewEditWorksheet-finish-button"
                        onClick={() => setStatus("initial")}>
                        Regenerate
                    </button>}
                    <button
                        type="button"
                        className="previewEditWorksheet-finish-button"
                        onClick={() => finishAndSave(worksheetData)}>
                        <img src={FinishIcon} alt="Finish and Save"/>
                        Finish and Save
                    </button>
                </div>
            </div>
            
            <div className="previewEditWorksheet-container">
                <div className="previewEditWorksheet-header">
                    <div className="previewEditWorksheet-header-leftItems">
                        <h1 className="previewEditWorksheet-title">Worksheet {currentWorksheetIndex + 1}: {worksheetDataInput[currentWorksheetIndex].version_title ?? worksheetDataInput[currentWorksheetIndex].worksheet_title}</h1>
                        <p className="previewEditWorksheet-subTitle">
                            {worksheetDataInput[currentWorksheetIndex].grade_level},&nbsp;
                            {worksheetDataInput[currentWorksheetIndex].num_questions} Questions<br/>
                            {prettyPrintActiveAccommodations(worksheetDataInput[currentWorksheetIndex].accommodations)}
                        </p>
                    </div>
                    <div className="previewEditWorksheet-header-rightItems">
                        { currentWorksheetIndex > 0 &&
                            <button
                                type="button"
                                className="previewEditWorksheet-changeWorksheet-button"
                                onClick={handlePrevWorksheet}>
                                    ← Previous Worksheet</button>
                        }
                        {   currentWorksheetIndex < worksheetData.length - 1 &&
                            <button
                                type="button"
                                className="previewEditWorksheet-changeWorksheet-button"
                                onClick={handleNextWorksheet}
                                >Next Worksheet →</button>
                        }
                    </div>
                </div>

                <div className="previewEditWorksheet-content">
                        <EditWorksheet 
                            worksheetIndex={currentWorksheetIndex} 
                            worksheetData={worksheetData[currentWorksheetIndex]} 
                            setWorksheetData={setWorksheetData}/>
                        <PreviewWorksheet 
                            enableExport = {false}
                            worksheetIndex={currentWorksheetIndex} 
                            worksheetData={worksheetData[currentWorksheetIndex]} />
                </div>
            </div>
        </div>
        </>
  );
}

PreviewEditWorksheet.propTypes = {
    worksheetDataInput: allWorksheetDataPropType,
    finishAndSave: PropTypes.func,
    setStatus: PropTypes.func
};

export default PreviewEditWorksheet;
