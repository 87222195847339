import React from 'react';
import PropTypes from 'prop-types';
import { pdf } from '@react-pdf/renderer';
import { useUser, useFunctions } from 'reactfire';
import { httpsCallable } from 'firebase/functions';

import '../PreviewWorksheet.css';

import { singleWorksheetDataPropType } from '../../CreateWorksheetConstants';
import { useTestingContext } from '../../../../TestingEnvContext';
import Dropdown from '../../../../components/Dropdown/Dropdown';

// Helper function to convert Blob to Base64
const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result.split(',')[1]); // Get only the Base64 part
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });

// Helper to download file at URL
async function downloadFileBlob(url, blob, fileName) {
    try {
        let downloadBlob;

        if (blob) {
            // If blob is provided, use it directly
            downloadBlob = blob;
        } else if (url) {
            // If URL is provided, fetch the blob from the URL
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Failed to download file');
            }

            downloadBlob = await response.blob();
        } else {
            throw new Error('No URL or Blob provided');
        }

        const downloadUrl = window.URL.createObjectURL(downloadBlob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName || 'downloaded_file'; // Default file name if not provided

        document.body.appendChild(link);

        link.click();

        window.URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);

    } catch (error) {
        console.error('Error downloading file:', error);
    }
}

function ExportDropdown({ worksheetDocument, worksheetData, worksheetIndex, setPopupOpen, setExportLoading, setGoogleDocURL }) {
    const { isTesting } = useTestingContext();
    // Authorize export
    const { data: user } = useUser();

    // Import Cloud Functions
    const functions = useFunctions();
    const exportToGoogleDocsCloudFunction = httpsCallable(functions, 'exportWorksheetToGoogleDocs');
    const exportToDocXCloudFunction = httpsCallable(functions, 'exportPDFtoDOCX');

    const exportToDocXTest = async () => {
        try {

            const token = await user.getIdToken(true);
            const blob = await pdf(worksheetDocument).toBlob();
            const base64String = await blobToBase64(blob);
            const name = `[WizLab] ${worksheetData.worksheet_title} (version ${worksheetIndex + 1})`;

            const response = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/exportPDFtoDOCX', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {
                        fileName: `${name}.pdf`,
                        fileData: base64String
                    }
                }),
            });

            const result = await response.json();
            console.log('Uploaded successfully:', result);

            await downloadFileBlob(result.result.url, null, `${name}.docx`)
        } catch (err) {
            console.error(err);
        }
    };

    const exportToDocX = async () => {
        try {

            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            const blob = await pdf(worksheetDocument).toBlob();
            const base64String = await blobToBase64(blob);
            const name = `[WizLab] ${worksheetData.worksheet_title} (version ${worksheetIndex + 1})`;

            const response = await exportToDocXCloudFunction(
                {
                    fileName: `${name}.pdf`,
                    fileData: base64String
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );

            const result = response.data;
            console.log('Uploaded successfully:', result);

            await downloadFileBlob(result.url, null, `${name}.docx`)
        } catch (err) {
            console.error(err);
        }
    };

    const exportToGoogleDocTest = async () => {
        try {
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }

            const token = await user.getIdToken(true);
            const blob = await pdf(worksheetDocument).toBlob();
            const base64String = await blobToBase64(blob);
            const name = `[WizLab] ${worksheetData.worksheet_title} (version ${worksheetIndex + 1})`;

            setExportLoading(true);
            const response = await fetch('http://127.0.0.1:5001/wizlab-8c906/us-central1/exportWorksheetToGoogleDocs-exportWorksheetToGoogleDocs', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    data: {
                        fileName: name,
                        fileData: base64String
                    }
                }),
            });

            const result = await response.json();
            console.log('Success:', result);

            setExportLoading(false);
            setPopupOpen(true);

            // Open the presentation in a new tab
            const docURLString = `https://docs.google.com/document/d/${result.docId}/edit`;

            setGoogleDocURL(docURLString);

            window.open(docURLString, '_blank');
        } catch (err) {
            console.error(err);
        }
    };

    const exportToGoogleDocs = async () => {
        try {
            if (!user) {
                const error = new Error('User is not authenticated');
                error.name = 'AuthenticationError';
                throw error;
            }
            
            const token = await user.getIdToken(true);
            const blob = await pdf(worksheetDocument).toBlob();
            const base64String = await blobToBase64(blob);
            const name = `[WizLab] ${worksheetData.worksheet_title} (version ${worksheetIndex + 1})`;

            setExportLoading(true);
            const response = await exportToGoogleDocsCloudFunction( 
                { 
                    fileName: name,
                    fileData: base64String
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );

            const result = response.data;
            console.log('Success:', result);

            setExportLoading(false);
            setPopupOpen(true);

            // Open the presentation in a new tab
            const docURLString = `https://docs.google.com/document/d/${result.docId}/edit`;

            setGoogleDocURL(docURLString);

            window.open(docURLString, '_blank');
        } catch (err) {
            console.error(err);
        }
    };


    return (
        <Dropdown buttonText="Export" className="previewWorksheet-dropbtn" >
            <button
                type="button"
                className="previewWorksheet-dropdown-option"
                onClick={async () => {
                    const name = `[WizLab] ${worksheetData.worksheet_title} (version ${worksheetIndex + 1}).pdf`
                    const blob = await pdf(worksheetDocument).toBlob();
                    downloadFileBlob(null, blob, name)
                }}
            >
                Download as PDF
            </button>
            <button
                type="button"
                className="previewWorksheet-dropdown-option"
                onClick={() => {
                    if (isTesting) { exportToDocXTest(); } else { exportToDocX(); }
                }}
            >
                Download as DOCX
            </button>

            <button
                type="button"
                className="previewWorksheet-dropdown-option"
                onClick={() => {
                    if (isTesting) { exportToGoogleDocTest(); } else { exportToGoogleDocs(); }
                }}
            >
                Export to Google Docs
            </button>
        </Dropdown>
    );
};


ExportDropdown.propTypes = {
    worksheetDocument: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    worksheetIndex: PropTypes.number,
    worksheetData: singleWorksheetDataPropType,
    setPopupOpen: PropTypes.func,
    setExportLoading: PropTypes.func,
    setGoogleDocURL: PropTypes.func,
}


export default ExportDropdown;