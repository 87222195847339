import React from 'react';
import PropTypes from 'prop-types';
import { TextWrap, EmbeddedTextWrap, NewLineWrap } from '../WorksheetWrappers';

import { questionPropType } from '../../pages/CreateWorksheet/CreateWorksheetConstants';

import { splitText } from '../LanguageAccessability';

// Create Document Component
function QuestionText({ question, questionIndex, config, isPdf }) {

    const fullText = `${questionIndex + 1}. ${question.question_text}`;

    return (

        <EmbeddedTextWrap
            isPdf={isPdf}
            elementKey={`question-text-${questionIndex + 1}`}
            styleProps={config}
            styleName=''
        >
            {splitText(fullText).map((part, index) => (

                <TextWrap
                    isPdf={isPdf}
                    styleProps={config}
                    styleNames={['questionText', 'inlineText', part.script]}
                    elementKey={`textSegment-${index + 1}`}
                    key={`textSegment-${index + 1}`}
                >
                    {part.text}
                </TextWrap>
            ))}

            {(question.question_type==="fill in the blank") && <TextWrap
                isPdf={isPdf}
                key={`answerLetter-${questionIndex + 1}`}
                styleProps={config}
                styleNames={['answerOption', 'inlineText']}
            >
                {Array.from({ length: question.num_lines }).map((_, index) => (
                    <NewLineWrap key={`${question.id || 'question'}-newLineWrap-${index + 1}`} />
                ))}
            </TextWrap>}
        </EmbeddedTextWrap>

    );
}

QuestionText.propTypes = {
    questionIndex: PropTypes.number,
    question: questionPropType,
    isPdf: PropTypes.bool.isRequired,
    config: PropTypes.shape({
        fontSize: PropTypes.number,
        lineSpacing: PropTypes.number,
    })
}

export default QuestionText;

