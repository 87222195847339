import React from "react";
import "./TeacherProfile.css";
import Navbar from "../../components/NavBar/NavBar";
import SiteHeader from "../../components/SiteHeader/SiteHeader";

import ProfileInfo from "./components/ProfileInfo";
import Banner from "./components/Banner";
import TopStatistics from "../Statistics/components/TopStatistics";
import AchievementExpanded from "../Achievements/components/achievementExpanded";

import achieveIcon from "../../assets/Achievements/win.jpg"

function TeacherProfile() {

	return (
		
		<div className="base-container">
			<Navbar/>
			<div className="base-content-container">
				<SiteHeader/>
				<div className="teacherProfile-container">
					<Banner/>

					<ProfileInfo/>

					<div className="section">
					<div className="sectionTitle">Statistics</div>
					<div className="sectionLink"> View All </div>
					</div>
					<TopStatistics/>

					<div className="section">
					<div className="sectionTitle">Achievements</div>
					<div className="sectionLink"> View All </div>
					</div>
					<AchievementExpanded icon={achieveIcon} title="Achievement 1" condition="Description of achievement." inprogress={60} total={100}/>

					<div className="content"/>

				</div>
			</div>
			
		</div>
	);
}

export default TeacherProfile;
